export var menu_title = {

        document: "Document",
        order: "Order",
        payment: "Payment",
        profile: "Profile",
        logout: "Logout",
        favourite_store: "Favourite Store"
}

export var title = {
        
        ///////////////for store Register //////////////////////////
        clear_all: 'clear all',
        language:"Language",
        name: "Name",
        email: "Email",
        password: "Password",
        first_name: "First Name",
        last_name: "last Name",
        confirm_password:"Confirm Password",
        select_country : "Select Country",
        select_city: "Select City",
        address: "Address",
        country_phone_code: "Phone Code",
        phone: "Phone Number",
        mobile_no: "Mobile No.",
        zipcode: "Zip Code",
        new_password: "New Password",
        fax_number: "Fax Number",
	    select_delivery: "Selct Delivery Type",
        latitude: "Latitude",
        longitude: "Longitude",
        image_url: "Image",
        referral_code: "Referral Code",
		is_required:"Required",
        delivery_time:"Delivery Time",

        register_your_account:"Register Your Account",
        confirmation_code:"Confirmation Code",
        sms_otp: "Sms Verification Code",
        email_otp: "Email Verification Code",
        verification:"Verification",
        
        details:"Detail",
        is_visible_in_store:"Visible In Store",
        is_item_in_stock:"Item In Stock",
        email_or_phone:"Email Or Phone",
        
        product:"Product",
        item:"Item",
        price:"Price",
        is_default_selected:"Default Selected",
        is_user_selected:"User Selected",
        item_note_here:"Item Note Here",

        old_password:"Old Password",
        country:"Country",
        city:"City",
        delivery_type:"Delivery Type",
        item_name:"Item Name",
        type_single:"Single",
        type_multiple:"Multiple",
        completed:"Completed",
        remaining:"Remaining",
        manual: "manual",
        social: "social",
        id:"Id",
        user:"User",
        provider:"Provider",
        payment_by:"Payment By",
        total:"Total",
        status:"Status",
        card:"Card",
        cash:"Cash",
        user_rating:"User Rating",
        provider_rating:"Provider Rating",
        
        product_name:"Product Name",
        date_time:"Date & Time",
        date:"Date",
        
        sort:"Sort",
        search:"Search",
        date_filter:"Date Filter",
        ascending:"Ascending",
        descending:"Descending",
        filter_id:"Id",
        filter_name:"Name",
        filter_user:"User",
        filter_provider:"Provider",
        filter_item:"Item",
        filter_product:"Product",

        please_contact_to_admin:"Please Contact To Admin",

        order:"Order",
        service_fees:"Service Fees",
        order_fees:"Order Fees",
        store_profit:"Store Profit",
        paid_service_fee:"Paid Service Fee",
        recieved_order_payment:"Recieved Order Payment",
        currency:"Currency",
        
        completed_order:"Completed Order",
        order_amount:"Order Amount",
        pay_by_admin:"Pay By Admin",


        ////////// daily earning ///////


        payment_status:"Payment Status",
        no_of_item:"No Of Item",
        total_cart_price:"Total Cart Price",
        total_order_price:"Total Order Price",
        store_have_service_payment:"Store Service Payment",
        total_store_income:"Total Store Income",
        total_order:"Total Order",
        accepted_order:"Accepted Order",
        cancelled_order:"Cancelled Order",
        
        referral_credit:"Referral Credit",
        total_referred:"Total Referred",

        all:"All",
        yes:"YES",
        no:"NO",

        doesnot_have_an_account:"Doesn't have an account?",
        user_title1:"Welcome to Edelivery",
        app_name:"Edelivery",
        
        signin_to_your_account:"Sign in to your account",
        forgot_password:"Forgot Password",

        offer:"Offer",
        unique_code:"Unique Code",
        exp_date:"Exp. Date",

        order_payment_paid_by_store:"Order Paymnet paid by Store?",
        vehicle:"Vehicle",


        ////////

        created_at:"Created At",
        store_accepted_at:"Store Accepted At",
        preparing_order_at:"Preparing Order At",
        order_ready_at:"Order Ready At",
        store_order_send_at:"Store Order Send At",
        provider_accepted_at:"Provider Accepted At",
        completed_at:"Completed At",
        delivered_at:"Delivered At",
        start_for_delivery_at:"Start For Delivery At",
        picked_order_at:"Picked Order At",
        arrived_on_store_at:"Arrived On Store At",
        start_for_pickup_at:"Start for Pickup At",


        //////////////////////////
        base_price:"Base Price",
        distance_price:"Distance Price",
        time_price:"Time Price",
        service_price:"Service Price",
        tax_price:"Tax Price",
        item_tax: 'Item Tax',
        service_tax: 'Service Tax',
        surge_price:"Surge Price",
        delivery_price:"DELIVERY PRICE",
        promo_bonus:"Promo Bonus",
        total_delivery_price:"TOTAL DELIVERY PRICE",
        total_service_cost: "TOTAL SERVICE COST",
        cart_price:"Cart Price",
        item_price: "Item Price",
        specification_price: 'Specification Price',
        total_item_cost: "TOTAL ITEM COST",
        order_payment:"Order Payment",
        delivery_payment:"Delivery Payment",

        ///////////

        wallet_payment:"Wallet Payment",
        cash_payment:"Cash Payment",
        other_payment:"Other Payment",
        user_payment:"User Payment",

        profit:"Profit",
        items:"items",
        qty:"Qty.",
        order_completed:"ORDER COMPLETED",

        already_account:"Already have an account?",

        search_store :"Search Store",
        search_item: "Search items",
        reopen_at: "ReOpen At",
        today: "Today",
        store_closed: "Store closed on",
        store_closed_today: "Store closed on today",
        min: "min",
        cuisine: "Cuisine",
        required:"Required",
        add_item_note: "Add Item Note",

        wallet: "Wallet",
        current_balance: "Current Balance",
        use_default: "Use by default",
        select_payment_method: "SELECT PAYMENT METHOD",
        expiry_date: "Expiry date",
        card_holder_name: "Card Holder Name",
        card_number: "Card Number",
        month: "Month",
        year: "Year",
        cvv: "Cvv",
        amount: "Amount",
        add_wallet: "Add Wallet",

        estimate_delivery_time: "Estimate Delivery Time",
        order_number: "ORDER NUMBER",
        current_order: "Current Order",
        order_history: "Order History",
        select_start_date: "Select Start Date",
        select_end_date: "Select End Date",
        asap: "ASAP",
        enter_location: "Enter your location",
        set_location: "Set Location",
        order_detail: "ORDER DETAIL",
        delivery_detail: "Delivery DETAIL",
        distance: "Distance",
        time: "Time",
        comment: "Comment",
        delivery_address: "Delivery Address",
        promocode: "Promo Code",
        order_details: "ODER DETAILS",
        store_closed_reopnat :"store is closed reopen at",
        invoice: "Invoice",
        payby: "Pay By",
        clear_cart_text: "some other store item already in cart, you want to clear a cart",
        invalid_amount : "The configuration allows you to specify a minimum\n" +
        "      amount,that order totals are required to meet",

        as_soon_as_possible: 'AS SOON AS POSSIBLE',
        schedule_order: 'schedule an order',
        invalid_date_time: 'Invalid Date & Time',

        store: 'Store',
        city_not_message: 'is currently not available in this location',
        enter_name: 'Enter Name',
        enter_mobile_no: 'Enter Mobile No.',
        enter_address: 'Enter Address',
        general_information: 'General Information',
        profile: 'Profile',
        favourite_store:'Favourite Store',
        document: 'Document',
        
        find_food:"Find Food",
        header_content:"Discover restaurants",
        header_content1:"that deliver near you",
        
        body_content:"Find food you",
        body_content1:"love from local",
        body_content2:"restaurants",
        body_content3:"and chain",
        body_content4:"favorites.",
        
        body_content5:"Get the app.",
        body_content6:"Order with a tap.",
        
        body_content7:"Your restaurant, delivered",
        body_content8:"Partner with Uber Eats for a new way to",
        body_content9:"make money, reach new diners, and deliver",
        body_content10:"food to your customers.",
        body_content11:"Get Started",
        body_content12:"Deliver the Eats",
        body_content13:"Deliver with Uber and earn on your",
        body_content14:"schedule. Depending on your city, you could",
        body_content15:"deliver with your car, bike, or scooter.",
        
        language_title:"ENGLISH",
        about:"About",
        see_all_cities:"See all cities",
        footer_content:"© 2018 Edelivery Technologies Inc.",
        privacy:"Privacy",
        terms:"Terms",
        
        error_content:"Sorry we are not delivering in your area!",
        update_profile:"Update Profile",
        
        faq:"FAQs",
        browse:"Browse",
        order_title:"Order",
        track:"Track",
        aboutus_content_string:"Edelivery has hundreds of restaurants to choose from. When you open the app, you can scroll through the feed for inspiration or search for a particular restaurant or cuisine. When you find something you like, tap to add it to your cart.",
        
        aboutus_content_string1:"Looking for more info?",
        aboutus_content_string2:"Check out the",
        aboutus_content_string3:"How Edelivery works",
        aboutus_content_string4:"Edelivery is the easy way to get the food you love delivered.",
        aboutus_content_string5:"When you’re ready to check out, you’ll see your address, an estimated delivery time, and the price of the order including tax and booking fee. When everything looks right, just tap Place order–and that’s it. We’ll automatically use the card on file so you never need cash.",
        aboutus_content_string6:"Follow your order in the app. First you’ll see the restaurant accept and start prepping. Then, when the order’s almost ready, a nearby Eber partner–in a car, on a bike, or scooter–will go to the restaurant to pick it up. Next, they’ll drive or ride to you. You’ll be able to see their name and photo and track progress on the map."
        
        
        
        
        
}

export var button = {
        filter: "Filter",
        invoice: "Invoice",
        reorder: 'reOrder',
        place_order: "PLACE ORDER",
        confirm: "Confirm",
        get_code: "Get Code",
        pay_now: "Pay Now",
	    submit:"Submit",
        register:"Register",
        login:"Login",
        busy: "Busy",
        login_button:"LOGIN",
        close:"Close",
        closed:"CLOSED",
        update:"Update",
        add:"Add",
        save:"Save",
        edit:"Edit",
        delete:"Delete",
        remove:"Remove",
        search: "Search",
        checkout: "CHECKOUT",
        change:"Change",
        select_file:"Select File",
        view_full_invoice:"View Full Invoice",
        edit_profile: "Edit Profile",
        update_profile: "Update Profile",
        done: 'Done',
        accept:"Accept",
        resject:"Reject",
        prepare_for_order:"Prepare For Order",
        order_ready:"Order Ready",
        assign_delivery_for_provider:"Assign Delivery For Provider",
        reassign_delivery_for_provider:"Re Assign Delivery For Provider",
        cancle:"Cancel",
        ok: "Ok",
        add_to_cart: "Add To Cart",
        cancel_request: "Cancel Request",
        reject:"Reject",
        view_order_detail:"View Order Detail",
        view_cart_detail:"View Cart Detail",
        pre:"Pre",
        next:"Next",
        get_confirmation_code: "Get Confirmation Code",
        apply:"Apply",
        reset: "Reset",
        export_excel:"Export",
        price_paid_by_store:"Price Paid By Store",
        add_card: "Add new card",

        register_now:"REGISTER NOW",
        forgot_password:"Forgot password?",
        sign_up:"SIGN UP",
        sign_in:"Sign In",
        to: "To",
        change_password: "Change Password",
        give_rate: "Give Rate",
        update_item: 'Update Item',

    view_detail: 'View Detail',
    track_order: 'Track Order'
	
}
export var status = {

    order_accepted: "Order accepted",
    order_prepared: "Order prepared",
    order_on_way: "Order on the way",
    order_doorstep: "Order on doorstep",

    created:"Created",
    waiting_for_accept:"Waiting for Accept",
    accepted:"Accepted",
    start_preparing_order:"Start Preparing order",
    order_ready:"Order Ready",
    waiting_for_delivery_man:"Waiting for Delivery Man",
    delivery_man_accepted:"Delivery Man Accepted",
    delivery_man_coming:"Delivery Man Coming",
    delivery_man_arrived:"Delivery Man Arrived",
    picked_order:"Delivery Man Picked Order ",
    started_for_delivery:"Started For Delivery",
    arrived_at_destination:"Delivery Man Arrived at Destination",
    delivered_ordered:"Delivered Order",
    assign_order_to_Delivery_man:"Order Assign To Delivery Man",
    rejected:"Rejected",
    cancelled:"Cancelled",
    store_cancelled: "Cancel By store",
    no_delivery_man_found:"No Delivery Man Found",
    delivery_man_rejected:"Delivery Man Rejected",
    delivery_man_cancelled:"Cancle By Delivery Man",
    user_cancelled:"Cancle By User",
    oredr_complete:"Order Complete",
    order_delivered:"Order Delivered",
    order_completed: 'Order Completed',
    completed:"Completed",
    not_completed:"Not Completed",
    cancelled_request:"Request Cancelled"
    
   
}


export var message = {
}

export var heading_title = {
    
    add_card: "Add Card",
    history:"History",
    order_list:"Order List",
    delivery_list:"Delivery List",
    item_images:"Item Images",
    orders:"Orders",
    you_are_decline:"You Are Decline",
    rating:"Rating",
    total_order:"Total Order",
    accepted_order:"Accepted Order",
    rejected_order:"Rejected Order",
    completed_order:"Completed Order",
    total_item_sold:"Total Item Sold",
    order_payment:"Order Payment",
    store_filter: "Store Filter",

    invoice_no:"Invoice No.",
    user_detail:"User Detail",
    provider_detail:"Provider Detail",
    order_detail:"Order Detail",
    order_status:"ORDER STATUS",
    distance:"Distance",
    total_time:"Total Time",
    total_items:"Total Items",
    delivery_fees:"DELIVERY FEES",
    total:"TOTAL",
    quantity:"QUANTITY",
    price:"PRICE",
    cart:"CART",
    order_fees:"ORDER FEES",
    attention: "Attention",


    cancel_order: "Cancel Order"

}
