import { Component, OnInit } from '@angular/core';
import { BooleanService } from '../boolean.service';
import { title, links, placeholder, label, button, description, price, menu_title, orderTable } from '../helper';
import { ViewEncapsulation } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Helper } from "../user_helper";
declare var jquery: any;
declare var $: any;

@Component({
    selector: 'app-favourite-stores',
    templateUrl: './favourite-stores.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [Helper]
})
export class FavouriteStoresComponent implements OnInit {

    public title: any = title;
    public links: any = links;
    public placeholder: any = placeholder;
    public label: any = label;
    public button: any = button;
    public description: any = description;
    public price: any = price;
    public menu_title: any = menu_title;
    public orderTable: any = orderTable;
    public store: any = null;

    model;
    model2;

    private user_id: string = '';
    private server_token: string = '';
    private favourite_stores: any[] = [];
    filtered_store_list: any[] = [];
    closeResult: string;
    product_group_list: any[] = [];
    delete_favourite_store_array: any[] = [];
    is_edit: boolean = false;

    constructor(public bln: BooleanService, public user_helper: Helper, private modalService: NgbModal) { }

    ngOnInit() {

        this.bln.showLogin = false;
        this.bln.showhedmid = true;
        this.bln.showcart = true;
        this.bln.isSticky = false;
        this.bln.showLink = false;
        this.bln.isHome = true;
        this.bln.cart = true;
        this.bln.showSignup = true;
        this.bln.isAdd_product = true;
        this.bln.isShop = true;
        this.bln.isInner_page = true;
        this.bln.isGreen = true;
        this.bln.isBod_btm_h = false;
        this.bln.isOrd_b = true;
        this.bln.address = true;


        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user._id) {
            this.user_id = user._id;
            this.server_token = user.server_token;
            this.favourite_stores = user.favourite_stores;
        }
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_FAVOURITE_STORE_LIST, { user_id: this.user_id, server_token: this.server_token }, (res_data) => {
            if (res_data.success) {
                this.filtered_store_list = res_data.favourite_stores;
            }
        });
    }

    check_store(store_id) {
        var index = this.delete_favourite_store_array.indexOf(store_id);
        if (index == -1) {
            this.delete_favourite_store_array.push(store_id);
        } else {
            this.delete_favourite_store_array.splice(index, 1);
        }
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    get_product_group_list(store, content2) {
        this.store = store;
        this.user_helper.router_id.sub_language = 0;
        this.store.languages_supported.forEach((lang, index) => {
            if (this.user_helper.router_id.languages[this.user_helper.router_id.main_language].code == lang.code) {
                this.user_helper.router_id.sub_language = index;
                localStorage.setItem('sub_language', index);
            }
        })
        if (store.is_store_can_create_group) {
            this.user_helper.http_post_method_requester('api/user/get_product_group_list', {
                store_id: store._id
            }, (res_data) => {
                if (res_data.success == true) {
                    this.product_group_list = res_data.product_groups;
                } else {
                    this.product_group_list = [];
                }
                this.modalService.open(content2, { ariaLabelledBy: 'modal-basic-title', windowClass: 'product_pop with_img', size: 'lg', centered: true }).result.then((result) => {
                    this.closeResult = `Closed with: ${result}`;
                }, (reason) => {
                    this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
                });
            })
        } else {
            this.get_item_list(null);
        }
    }
    get_item_list(product_ids) {
        $('.close').click();
        this.user_helper.router_id.user_current_store = this.store;
        this.user_helper.router_id.user.store_id = this.store._id;
        this.user_helper.router_id.product_ids = product_ids;

        let store_name = this.store.name.replace(/ /g, "-");
        store_name = store_name.toLowerCase();

        this.user_helper.router.navigate(["city_name" + '/' + "delivery_name" + '/' + store_name + '/' + this.store._id]);
    }

    delete_store() {
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.REMOVE_FAVOURITE_STORE, { store_id: this.delete_favourite_store_array, user_id: this.user_id, server_token: this.server_token }, (res_data) => {
            this.favourite_stores = res_data.favourite_stores;
            let user = JSON.parse(localStorage.getItem('user'));
            user.favourite_stores = res_data.favourite_stores;
            localStorage.setItem('user', JSON.stringify(user));
            this.delete_favourite_store_array = [];
            this.is_edit = false;
            this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_FAVOURITE_STORE_LIST, { user_id: this.user_id, server_token: this.server_token }, (res_data) => {
                if (res_data.success) {
                    this.filtered_store_list = res_data.favourite_stores;
                } else {
                    this.filtered_store_list = [];
                }
            });
        });
    }
}
