import { Component, OnInit } from '@angular/core';
import { BooleanService } from '../boolean.service';
import {title, links, placeholder, label, button, description, price, menu_title, orderTable, status} from '../helper';
import {ViewEncapsulation} from '@angular/core';
import {Helper} from "../user_helper";
declare var jquery:any;
declare var $ :any;
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-wallet_history',
  templateUrl: './wallet_history.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [Helper]
})
export class WalletHistoryComponent implements OnInit {

    public title:any = title;
    public links:any = links;
    public placeholder:any = placeholder;
    public label:any = label;
    public button:any = button;
    public description:any = description;
    public price:any = price;
    public menu_title:any = menu_title;
 
    wallet_history_list: any[] = [];
    private user_id: string = '';
    private server_token: string = '';
    ORDER_STATE:any;
    ORDER_STATUS_ID: any;
    status:any;
    private order_id: string = '';
    order_status: number = 0;
    closeResult: string;

    constructor(  public bln: BooleanService, private modalService: NgbModal , public user_helper: Helper ) { }

    ngOnInit() {

        this.bln.showLogin = false;
        this.bln.showhedmid = true;
        this.bln.showcart = true;
        this.bln.isSticky = false;
        this.bln.showLink = false;
        this.bln.isHome = true;
        this.bln.cart = true;
        this.bln.showSignup = true;
        this.bln.isAdd_product = true;
        this.bln.isShop = true;
        this.bln.isInner_page = true;
        this.bln.isGreen = true;
        this.bln.isBod_btm_h = false;
        this.bln.isOrd_b = true;
        this.ORDER_STATE=this.user_helper.ORDER_STATE;
        this.ORDER_STATUS_ID = this.user_helper.ORDER_STATUS_ID;
        this.status=status
        this.bln.address = true;

        let user = JSON.parse(localStorage.getItem('user'));
        if(user && user._id){
            this.user_id = user._id;
            this.server_token = user.server_token;
        } else {
            this.user_helper.router.navigate(['']);
        }

        this.history();

    }

    sortOrder(a, b) {
        if (a.unique_id < b.unique_id)
            return 1;
        if (a.unique_id > b.unique_id)
            return -1;
    }


    history(){
        this.user_helper.user_cart.myLoading = true;
        this.user_helper.http_post_method_requester('api/admin/get_wallet_history', {id: this.user_id, server_token: this.server_token, type:this.user_helper.ADMIN_DATA_ID.USER}, (res_data) => {

            this.user_helper.user_cart.myLoading = false;
            if(res_data.success){
                this.wallet_history_list = res_data.wallet_history;
                //this.wallet_history_list.sort(this.sortOrder);
            } else {
                this.wallet_history_list = [];
            }
        });
    }

}
