import { Component } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { button, title, placeholder } from '../helper';
import { BooleanService } from '../boolean.service';
import { Helper } from '../user_helper';
import { HeaderComponent } from './header.component';
declare var $;
import { Response, Http } from '@angular/http';
import { map } from "rxjs/operators";
declare var gapi;

export interface UserLogin {
    email: string,
    social_id: string,
    login_by: any,
    cart_unique_token: string,
    password: string
}
export interface UserRegister {
    _id: String,
    server_token: String,
    first_name: String,
    last_name: String,
    email: String,
    currency: String,
    password: String,
    country_code: String,
    social_unique_id: String,
    login_by: String,
    confirm_password: String,
    country_id: Object,
    city: Object,
    address: String,
    country_phone_code: string,
    cart_unique_token: string,
    phone: Number,
    image_url: String,
    referral_code: String,
    is_email_verified: Boolean,
    is_phone_number_verified: Boolean
}
export interface UserForgotPassword {
    email: String
}

@Component({
    selector: 'ngbd-modal-basic',
    templateUrl: './modal-basic.html',
    providers: [Helper]
})
export class NgbdModalBasic {
    closeResult: string;
    public button: any = button;
    public title: any = title;
    public placeholder: any = placeholder;

    public user_login: UserLogin;

    "tel_url" = "../assets/images/flag.svg";

    fname: string;
    sname: string;
    password: string;
    email: string;
    otp_for_email: number;
    otp_for_sms: number;

    opt_error_message: number = 0;
    type: number = 0;
    mobile: number;
    country_phone_list: any[] = [];
    public user_id: string = '';
    private server_token: string = '';
    setting_data: any = {};
    email_placeholder: Number = 1;
    email_or_phone_error: Boolean = false;
    private user_register: UserRegister;
    country_list: any[] = [];
    error_message: string = '';
    referral_code: string = '';
    sms_otp: string = '';
    email_otp: string = '';
    is_referral_apply: boolean = false;
    private user_forgot_password: UserForgotPassword;
    phoneError = false

    constructor(private modalService: NgbModal, public HeaderComponent: HeaderComponent, public user_helper: Helper, public bln: BooleanService) {
        this.fname = "";
        this.sname = "";
        this.password = "";
        this.email = "";
        this.mobile = null;
        this.bln.showSignup = true;

    }

    ngOnInit() {
        this.user_login = {
            cart_unique_token: localStorage.getItem('cart_unique_token'),
            email: '',
            password: '',
            login_by: this.title.manual,
            social_id: ''
        }
        this.user_forgot_password = {
            email: "",
        }

        this.user_register = {
            cart_unique_token: localStorage.getItem('cart_unique_token'),
            _id: "",
            server_token: "",
            first_name: "",
            country_code: "",
            currency: "",
            last_name: "",
            email: "",
            password: "",
            social_unique_id: "",
            login_by: this.title.manual,
            confirm_password: "",
            country_id: "",
            city: "",
            address: "",
            country_phone_code: "",
            phone: null,
            image_url: "./default.png",
            referral_code: "",
            is_phone_number_verified: false,
            is_email_verified: false

        }
        

        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user._id) {
            this.user_id = user._id;
            this.server_token = user.server_token;
        }
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_SETTING_DETAIL, {}, (res_data) => {

            this.user_helper.myLoading = false;
            this.setting_data = res_data.setting

            if (this.setting_data.is_user_login_by_phone == true && this.setting_data.is_user_login_by_email == true) {
                this.email_placeholder = 1
            }
            else if (this.setting_data.is_user_login_by_phone == true) {
                this.email_placeholder = 2
            }
            else if (this.setting_data.is_user_login_by_email == true) {
                this.email_placeholder = 3
            }

        });
        this.user_helper.http.get(this.user_helper.CONSTANT.BASE_URL + this.user_helper.GET_METHOD.GET_COUNTRY_LIST).pipe(map((res) => res.json())).subscribe((res_data) => {
            this.country_list = res_data.countries;
        });
        // this.user_helper.http.get(this.user_helper.GET_METHOD.GET_COUNTRY_LIST).map((response: Response) => response.json()) .subscribe(res_data => {
        // this.country_list = res_data.countries;
        //     },
        // (error: any) => {
        //     this.user_helper.http_status(error)
        // });
    }
    ngAfterViewInit() {
        this.user_register.country_phone_code = this.user_helper.COUNTRIES[0].calling_codes[0];
        this.user_register.country_code = this.user_helper.COUNTRIES[0].code;
        this.user_register.currency = this.user_helper.COUNTRIES[0].currencies[0];

    }
    remove_referral() {
        this.referral_code = '';
        this.user_register.referral_code = '';
        this.is_referral_apply = false;
    }

    check_referral() {
        this.user_helper.myLoading = true;
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.CHECK_REFERRAL, { country_code: this.user_register.country_code, referral_code: this.referral_code, type: 7 }, (res_data) => {
            this.user_helper.myLoading = false;
            if (res_data.success) {
                this.user_helper.data.storage = {
                    "message": "Code Applied Successfully!!!",
                    "class": "alert-info"
                }
                this.user_helper.message()
                this.is_referral_apply = true;
                this.user_register.referral_code = this.referral_code;
            }
            else {
                this.user_helper.data.storage = {
                    "code": res_data.error_code,
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.message()
                this.is_referral_apply = false;
                this.referral_code = '';
            }
        });
    }

    /*select_country() {
        var index = this.country_list.findIndex((x) => x.country_phone_code == this.user_register.country_phone_code);
        this.user_register.country_id = this.country_list[index]._id;
    }*/

    userForgotPassword(forgotpassworddata) {
        this.user_helper.myLoading = true;
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.FORGOT_PASSWORD, { email: forgotpassworddata.email.trim(), type: 7 }, (res_data) => {
            this.user_helper.myLoading = false;
            if (res_data.success == false) {
                this.user_helper.data.storage = {
                    "code": res_data.error_code,
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.message()
                this.error_message = this.user_helper.ERROR_CODE[res_data.error_code];
            }
            else {
                this.user_helper.data.storage = {
                    "message": this.user_helper.MESSAGE_CODE[res_data.message],
                    "class": "alert-info"
                }
                $('#success_modal').click();
                $('#forgot_modal').click();
                this.error_message = '';
            }
        });
    }

    open_login_modal() {
        $('#success_modal123').click();
    }

    public formData = new FormData();
    userRegister(userdata, content10) {
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (isNaN(userdata.phone)) {
            this.phoneError = true
        } else {
            this.phoneError = false
            if (reg.test(userdata.email.trim())) {

                this.user_helper.myLoading = true;
                //this.user_register=userdata
                if (this.setting_data.is_user_sms_verification == true || this.setting_data.is_user_mail_verification == true) {
                    this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.ADMIN_OTP_VERIFICATION, { type: 7, email: userdata.email, phone: userdata.phone, country_phone_code: userdata.country_phone_code }, (res_data) => {
                        this.user_helper.myLoading = false;
                        $('#register_modal').click();
                        if (res_data.success == true) {
                            //this.helper.string_log("email" , res_data.otp_for_email)
                            //this.helper.string_log("sms" , res_data.otp_for_sms)
                            this.type = 0;
                            console.log(this.user_register);
                            this.sms_otp = '';
                            this.email_otp = '';
                            this.open2(content10);
                            this.otp_for_email = res_data.otp_for_email
                            this.otp_for_sms = res_data.otp_for_sms
                            if (this.setting_data.is_user_sms_verification == false) {
                                $("#otp_for_sms").css("display", "none");
                            }

                            if (this.setting_data.is_user_mail_verification == false) {
                                $("#otp_for_email").css("display", "none");
                            }
                        }
                        else {
                            this.user_helper.data.storage = {
                                "code": res_data.error_code,
                                "message": this.user_helper.ERROR_CODE[res_data.error_code],
                                "class": "alert-danger"
                            }
                            this.user_helper.message()

                        }
                    });
                }
                else {

                    this.register()
                }
            }
        }
    }
    select_country() {
        this.is_referral_apply=false;
        this.user_register.referral_code = "";
        var index = this.user_helper.COUNTRIES.findIndex((x) => x.code == this.user_register.country_code);
        this.user_register.country_code = this.user_helper.COUNTRIES[index].code;
        this.country_phone_list = this.user_helper.COUNTRIES[index].calling_codes;
        this.user_register.currency = this.user_helper.COUNTRIES[index].currencies[0];
    }
    register() {
        this.user_helper.myLoading = true;
        this.formData.append('phone', String(this.user_register.phone));
        this.formData.append('password', this.user_register.password.trim());
        this.formData.append('cart_unique_token', this.user_register.cart_unique_token);
        //this.formData.append('country_id', this.user_register.country_id.toString());
        this.formData.append('city', '');
        this.formData.append('social_id', this.user_register.social_unique_id.toString());
        this.formData.append('login_by', this.user_register.login_by.toString());
        var selectedCountryCode = $(".ph_code").val()
        this.formData.append('country_phone_code', selectedCountryCode);
        // this.formData.append('country_phone_code', this.user_register.country_phone_code);
        this.formData.append('country_code', String(this.user_register.country_code));
        this.formData.append('currency', String(this.user_register.currency));
        this.formData.append('first_name', this.user_register.first_name.trim());
        this.formData.append('last_name', this.user_register.last_name.trim());
        this.formData.append('email', this.user_register.email.trim());
        this.formData.append('address', '');
        this.formData.append('referral_code', this.user_register.referral_code.toString());
        this.formData.append('is_phone_number_verified', String(this.user_register.is_phone_number_verified));
        this.formData.append('is_email_verified', String(this.user_register.is_email_verified));

        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.REGISTER, this.formData, (res_data) => {

            this.user_helper.myLoading = false;
            if (res_data.success == false) {
                this.user_helper.data.storage = {
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.message();
                this.error_message = this.user_helper.ERROR_CODE[res_data.error_code];
                this.formData = new FormData();
                if (this.user_register.login_by == this.title.social) {
                    this.user_register.password = '123456';
                    this.user_register.confirm_password = '123456';
                }
            }
            else {
                this.error_message = '';
                this.user_helper.data.storage = {
                    "message": this.user_helper.MESSAGE_CODE[res_data.message],
                    "class": "alert-info"
                }
                localStorage.setItem('user', JSON.stringify(res_data.user));
                this.user_helper.check_detail();
                this.user_helper.router.navigate(['home']);
                $('#register_modal').click();
            }
        });
    }
    open(content) {
        this.user_login = {
            cart_unique_token: localStorage.getItem('cart_unique_token'),
            email: '',
            password: '',
            login_by: this.title.manual,
            social_id: ''
        }
        this.user_forgot_password = {
            email: "",
        }
        this.error_message = '';
        this.user_register = {
            cart_unique_token: localStorage.getItem('cart_unique_token'),
            _id: "",
            server_token: "",
            first_name: "",
            last_name: "",
            email: "",
            password: "",
            social_unique_id: "",
            login_by: this.title.manual,
            confirm_password: "",
            country_id: "",
            currency: "",
            country_code: "",
            city: "",
            address: "",
            country_phone_code: "",
            phone: null,
            image_url: "./default.png",
            referral_code: "",
            is_phone_number_verified: false,
            is_email_verified: false

        }
        this.country_phone_list = this.user_helper.COUNTRIES[0].calling_codes;
        this.user_register.country_phone_code = this.user_helper.COUNTRIES[0].calling_codes[0];
        this.user_register.country_code = this.user_helper.COUNTRIES[0].code;
        this.user_register.currency = this.user_helper.COUNTRIES[0].currencies[0];


        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    open2(content2) {
        console.log(this.user_register);
        this.modalService.open(content2, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    google_register(content10) {
        this.user_helper.user_cart.myLoading = true;
        gapi.load('auth2', () => {
            let auth2 = gapi.auth2.init({
                client_id: '866731808070-rc1i2rpmnjqv2u7la0kh0odr5olu66os.apps.googleusercontent.com',
                fetch_basic_profile: true,
                scope: 'profile'
            });

            auth2.signIn().then(()=> {
                this.user_helper.user_cart.myLoading = false;
                if (auth2.isSignedIn.get()) {
                    let profile = auth2.currentUser.get().getBasicProfile();

                    this.user_register.social_unique_id = profile.getId();
                    this.user_register.login_by = this.title.social;
                    this.user_register.first_name = profile.getGivenName();
                    this.user_register.last_name = profile.getFamilyName();
                    this.user_register.email = profile.getEmail();
                    this.user_register.password = "";
                }
            });
        });
    }

    google_login(content10){
        this.user_helper.user_cart.myLoading = true;
        gapi.load('auth2', () => {
            let auth2 = gapi.auth2.init({
                client_id: '866731808070-rc1i2rpmnjqv2u7la0kh0odr5olu66os.apps.googleusercontent.com',
                fetch_basic_profile: true,
                scope: 'profile'
            });

            auth2.signIn().then(()=> {
                this.user_helper.user_cart.myLoading = false;
                if (auth2.isSignedIn.get()) {
                    let profile = auth2.currentUser.get().getBasicProfile();

                    this.user_login.social_id = profile.getId();
                    this.user_login.login_by = this.title.social;
                    this.Login(content10)
                }
            });
        });
    }

    userLogin(logindata, content10) {
        this.user_login.social_id = '';
        this.user_login.login_by = this.title.manual

        this.user_login.email = this.user_login.email.trim();
        logindata.email = logindata.email.trim();
        if (this.email_placeholder == 1) {
            var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
            if (!isNaN(logindata.email) || reg.test(logindata.email)) {
                this.email_or_phone_error = false;
                this.Login(content10)
            }
            else {
                this.email_or_phone_error = true;
            }
        }
        else {
            this.email_or_phone_error = false;
            this.Login(content10)
        }
    }

    Login(content10) {
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.LOGIN, this.user_login, (res_data) => {
            if (res_data.success) {
                $('#login_modal').click();
                this.user_register = res_data.user;
                if ((this.setting_data.is_user_sms_verification == true && res_data.user.is_phone_number_verified == false) && (this.setting_data.is_user_mail_verification == true && res_data.user.is_email_verified == false)) {
                    var otp_json = { type: 7, email: res_data.user.email, country_phone_code: res_data.user.country_phone_code, phone: res_data.user.phone }
                    this.generate_otp(otp_json, content10)
                }
                else if (this.setting_data.is_user_sms_verification == true && res_data.user.is_phone_number_verified == false) {
                    this.generate_otp({ type: 7, country_phone_code: res_data.user.country_phone_code, phone: res_data.user.phone }, content10)
                }
                else if (this.setting_data.is_user_mail_verification == true && res_data.user.is_email_verified == false) {
                    this.generate_otp({ type: 7, email: res_data.user.email }, content10)
                }
                else {
                    localStorage.setItem('user', JSON.stringify(res_data.user));
                    this.user_helper.check_detail();
                    this.error_message = '';
                    this.user_helper.user_cart.myLoading = false;
                    this.user_helper.router.navigate(['home']);
                }
            } else {
                this.user_helper.data.storage = {
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.message();
                this.error_message = this.user_helper.ERROR_CODE[res_data.error_code];
            }
        });
    }
    generate_otp(otp_json, content10) {
        this.user_helper.myLoading = true;
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.ADMIN_OTP_VERIFICATION, otp_json, (res_data) => {
            this.user_helper.myLoading = false;
            if (res_data.success == true) {
                //this.user_helper.string_log("email" , res_data.otp_for_email)
                //this.user_helper.string_log("sms" , res_data.otp_for_sms)
                this.type = 1;
                this.sms_otp = '';
                this.email_otp = '';
                this.open2(content10);
                this.otp_for_email = res_data.otp_for_email
                this.otp_for_sms = res_data.otp_for_sms

                if (this.user_register.is_phone_number_verified == true || this.setting_data.is_user_sms_verification == false) {
                    $("#otp_for_sms").css("display", "none");
                }

                if (this.user_register.is_email_verified == true || this.setting_data.is_user_mail_verification == false) {
                    $("#otp_for_email").css("display", "none");
                }
            }
            else {
                this.user_helper.data.storage = {
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.message()

            }
        });
    }
    otp_var(otp) {
        if (otp.sms_otp == undefined) {
            if (otp.email_otp == this.otp_for_email) {

                this.otp_verified({ user_id: this.user_register._id, email: this.user_register.email, server_token: this.user_register.server_token, is_email_verified: true })
            }
            else {
                this.opt_error_message = 1;
            }
        }
        else if (otp.email_otp == undefined) {
            if (otp.sms_otp == this.otp_for_sms) {
                this.otp_verified({ user_id: this.user_register._id, phone: this.user_register.phone, server_token: this.user_register.server_token, is_phone_number_verified: true })
            }
            else {
                this.opt_error_message = 2;
            }
        }
        else {
            if (otp.sms_otp == this.otp_for_sms && otp.email_otp == this.otp_for_email) {
                this.otp_verified({ user_id: this.user_register._id, email: this.user_register.email, phone: this.user_register.phone, server_token: this.user_register.server_token, is_email_verified: true, is_phone_number_verified: true })
            }
            else {
                this.opt_error_message = 3;
            }
        }
    }
    otp_verified(otp_verified_json) {
        $('#ver_close').click();
        this.user_helper.myLoading = true;
        
        if (this.type == 0) {
            this.user_register.is_email_verified = true;
            this.user_register.is_phone_number_verified = true;
            this.register();
        } else {
            this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.OTP_VERIFICATION, otp_verified_json, (res_data) => {
                this.user_helper.myLoading = false;
                if (res_data.success == true) {

                    localStorage.setItem('user', JSON.stringify(this.user_register));
                    this.user_helper.data.storage = {
                        "message": this.user_helper.MESSAGE_CODE[res_data.message],
                        "class": "alert-info"
                    }
                    this.user_helper.check_detail();
                    this.user_helper.router.navigate(['home']);

                }
                else {
                    this.user_helper.data.storage = {
                        "code": res_data.error_code,
                        "message": this.user_helper.ERROR_CODE[res_data.error_code],
                        "class": "alert-danger"
                    }
                }

            });
        }
    }


    onSubmit(data) {
    }


}