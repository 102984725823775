import { Component, OnInit } from '@angular/core';
import { BooleanService } from '../boolean.service';
import { button, language, menu_title, label, title, placeholder, description, ordList, price } from '../helper';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ViewEncapsulation } from '@angular/core';
import { Params } from '@angular/router';
import { Location } from '@angular/common';
import { Helper } from "../user_helper";
declare var google;
declare var jQuery;
export interface CartProductItems {
    item_id: Object,
    unique_id: Number,
    item_tax: Number,
    item_name: string,
    quantity: Number,
    image_url: any,
    details: string,
    specifications: any[],
    item_price: Number,
    total_price: Number,
    tax: Number,
    total_specification_price: Number,
    total_specification_tax: Number,
    total_tax: Number,
    total_item_tax: Number,
    max_item_quantity: Number,
    total_item_price: Number,
    note_for_item: string
}

export interface cartProducts {
    items: any[],
    product_id: Object,
    product_name: string,
    unique_id: number,
    total_item_tax: number,
    total_item_price: number
}
declare var $: any;

@Component({
    selector: 'app-edit_order',
    templateUrl: './edit_order.component.html',
    encapsulation: ViewEncapsulation.None,
    providers: [Helper]

})
export class EditOrderComponent implements OnInit {

    closeResult: string;
    public button: any = button;
    public language: any = language;
    public menu_title: any = menu_title;
    public label: any = label;
    public title: any = title;
    public placeholder: any = placeholder;
    public description: any = description;
    private ordList: any = ordList;
    public price: any = price;

    private city_name: string = '';
    public current_store: any;
    private current_location: any;
    private store_id: string;
    public filtered_product_item_list: any[] = [];
    private product_item_list: any[] = [];
    current_item: any = { image_url: [] };
    private cartProductItems: CartProductItems;
    private cartProducts: cartProducts;
    current_main_item: any = {};
    required_count: number = 0;
    private total: number = 0;
    required_temp_count: number = 0;
    delivery_currency: string = '';
    qty: number = 1;
    private cart_unique_token: string = '';
    private user_id: string = '';
    private server_token: string = '';
    public user_profile: string = '';
    note_for_item: string = '';
    product_name: string = '';
    product_unique_id: number = 0;
    private cart_data: any = {};
    OrderDetail: any = null;
    selected_product_id: Object = null;
    order_id: Object = null;

    filter_item_name: string = '';

    start_screex: number = 0;
    end_screex: number = 0;

    public device_type: string = "web";

    selected_item_index: number = 0;
    selected_product_index: number = 0;


    public counter: number = 0;
    current_language: any;
    category_id: any;
    increment() {
        this.counter += 1;
    }

    decrement() {
        if (this.counter > 0) {
            this.counter -= 1;
        }
    }

    constructor(public bln: BooleanService, private location: Location, private modalService: NgbModal, public user_helper: Helper) { }

    ngOnInit() {
        this.current_language = Number(localStorage.getItem('main_language'));
        this.bln.showLogin = false;
        this.bln.showhedmid = true;
        this.bln.showcart = false;
        this.bln.isSticky = false;
        this.bln.showLink = false;
        this.bln.cart = true;
        this.bln.isHome = true;
        this.bln.showSignup = true;
        this.bln.isAdd_product = false;
        this.bln.isShop = false;
        this.bln.isInner_page = true;
        this.bln.isGreen = true;
        this.bln.isBod_btm_h = true;
        this.bln.address = true;
        this.order_id = this.user_helper.router_id.user.order_id;
        /* slide */
        $(".box ul li a").click(function (e) {
            e.preventDefault();
            var hrefVal = $(this).attr("href");
            var sectionOffset = $(hrefVal).offset().top - 120;
            $('html, body').animate({
                scrollTop: sectionOffset
            }, 500);
        });
        $(window).scroll(function () {
            if ($(this).scrollTop() > 555) {
                $('#fix_bar').addClass('fix_bar')
                $('#fix_bar').css('margin-top', 'unset');
            } else {
                $('#fix_bar').removeClass('fix_bar')
                var height = jQuery('.banner_text').height();
                $('#fix_bar').css('margin-top', ((height / 2) + 10) + 'px');
            }

            var height = $('.shop').height() + $('.shop_banner').height() + $('#fix_bar').height();
            // height = height.slice(0, -2);
            // height = Number(height);

            if ($(this).scrollTop() > height - 50) {

                if (($(window).scrollTop() + $(window).height()) >= ($(document).height() - $('footer').height())) {
                    $('.fix_bar1').css('width', '33.33%')
                    $('.fix_bar1').css('left', 'unset')
                    $('#fix_bar1').removeClass('fix_bar1')
                } else {
                    $('#fix_bar1').addClass('fix_bar1')

                    var width = $('.box').width();
                    var full_width = $(window).width();

                    var margin = $('.tab3 .row').css('marginLeft');
                    margin = parseInt(margin)
                    margin = Math.abs(margin * 2);

                    var left = (((full_width - (width + margin)) / 2));

                    if ($(window).width() > 1499) {
                        $('.fix_bar1').css('width', ((width + margin) / 3))
                        $('.fix_bar1').css('left', (((width + margin) * 2) / 3) + left)
                    } else {
                        $('.fix_bar1').css('width', (width + margin) / 2)
                        $('.fix_bar1').css('left', ((width + margin) / 2) + left)
                    }
                    if ($('#fix_bar1').height() > ($(window).height() - 150)) {
                        $('.fix_bar1').css('bottom', '30px')
                        $('.fix_bar1').css('top', 'unset')
                    } else {
                        $('.fix_bar1').css('top', '60px')
                        $('.fix_bar1').css('bottom', 'unset')
                    }
                }

            } else {
                $('.fix_bar1').css('width', '33.33%')
                $('.fix_bar1').css('left', 'unset')
                $('#fix_bar1').removeClass('fix_bar1')
            }
        });

        this.current_store = {
            price_rating: 0,
            famous_for: []
        };
        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user._id) {
            this.user_id = user._id;
            this.server_token = user.server_token;
            this.user_profile = user.image_url;

            this.get_order_detail();
        }
        this.cartProductItems = {
            item_id: null,
            unique_id: 0,
            item_name: '',
            item_tax: 0,
            quantity: 0,
            image_url: [],
            details: '',
            specifications: [],
            item_price: 0,
            total_price: 0,
            tax: 0,
            total_specification_price: 0,
            total_specification_tax: 0,
            total_item_price: 0,
            max_item_quantity: 0,
            total_tax: 0,
            total_item_tax: 0,
            note_for_item: ''
        }
        this.cartProducts = {
            items: [],
            product_id: null,
            product_name: '',
            unique_id: 0,
            total_item_price: 0,
            total_item_tax: 0
        }

    }
    basket() {
        console.log(this.OrderDetail.cart_detail);
        let json = {
            store_id: this.current_store._id,
            user_id: this.user_id,
            server_token: this.server_token,
            order_id: this.order_id,
            total_cart_price: this.OrderDetail.cart_detail.total_cart_price,
            total_item_count: this.OrderDetail.cart_detail.total_item_count,
            total_item_tax: this.OrderDetail.cart_detail.total_item_tax,
            order_details: this.OrderDetail.cart_detail.order_details
        }

        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.USER_UPDATE_ORDER, json, (res_data) => {
            this.user_helper.myLoading = false;

            if (res_data.success) {
                this.user_helper.data.storage = {
                    "message": this.user_helper.MESSAGE_CODE[res_data.message],
                    "class": "alert-info"
                }
            } else {
                this.user_helper.data.storage = {
                    "code": res_data.error_code,
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-info"
                }
            }
            this.user_helper.router.navigate(['delivery-coming']);

        });
    }
    get_order_detail() {

        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            order_id: this.order_id
        }
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.GET_ORDER_DETAIL, json, (res_data) => {
            this.user_helper.myLoading = false;
            if (res_data.success) {
                this.OrderDetail = res_data.order;
                this.OrderDetail.currency = res_data.order.country_detail.currency_sign;

                if (!this.OrderDetail.cart_detail.language) {
                    this.OrderDetail.cart_detail.language = 0;
                }

                if (this.OrderDetail.order_status_id == this.user_helper.ORDER_STATUS_ID.CANCELLED || this.OrderDetail.order_status_id == this.user_helper.ORDER_STATUS_ID.REJECTED) {
                    this.user_helper.router.navigate(['orders']);
                } else {
                    this.get_item_list()
                }
            } else {
                this.user_helper.data.storage = {
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.user_helper.router.navigate(['']);
            }
        });

    }
    open_item_modal(item, item_index, product_index, event, content) {

        this.note_for_item = '';
        if (event.target.attributes.class != undefined) {
            if (event.target.attributes.class.value !== "carousel-indicators_li" && event.target.attributes.class.value !== "carousel-indicators_li active") {
                this.qty = 1;
                this.required_count = 0;
                this.current_item = JSON.parse(JSON.stringify(item));
                this.current_main_item = item;
                this.product_unique_id = this.filtered_product_item_list[product_index]._id.unique_id;
                this.product_name = this.filtered_product_item_list[product_index]._id.name;
                this.calculate_is_required();
                this.calculateTotalAmount();
                this.open(content);
            }
        } else {
            this.qty = 1;
            this.required_count = 0;
            this.current_item = JSON.parse(JSON.stringify(item));
            this.current_main_item = item;
            this.product_unique_id = this.filtered_product_item_list[product_index]._id.unique_id;
            this.product_name = this.filtered_product_item_list[product_index]._id.name;
            this.calculate_is_required();
            this.calculateTotalAmount();
            this.open(content);

        }
    }

    redirect(index, id) {
        // id.scrollIntoView({ behavior: 'smooth', block: 'start' });
        this.category_id = id;
        document.querySelector('#tab' + index).scrollIntoView({ behavior: 'smooth', block: 'start', inline: "nearest" });
    }


    get_item_list() {


        var json = {
            store_id: this.OrderDetail.store_id,
            user_id: this.user_id,
            server_token: this.server_token,
            cart_unique_token: this.cart_unique_token,
            product_ids: this.user_helper.router_id.product_ids
        }
        var y_name = "";
        var x_name = "";
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.USER_GET_STORE_PRODUCT_ITEM_LIST, json, (res_data) => {

            this.user_helper.myLoading = false;
            if (res_data.success) {
                let specification_price;
                let product_array = res_data.products.filter((product_data) => {
                    let item_array = product_data.items.filter((item_data) => {
                        specification_price = 0;
                        item_data.specifications.filter((specification_group) => {
                            y_name = specification_group.name;
                            if (typeof specification_group.name == "object") {
                                y_name = specification_group.name[this.OrderDetail.cart_detail.language];
                                if (!y_name) {
                                    y_name = specification_group.name[0];
                                }
                            }
                            specification_group.name = y_name;
                            specification_group.list.filter((specification) => {
                                x_name = specification.name;
                                if (typeof specification.name == "object") {
                                    x_name = specification.name[this.OrderDetail.cart_detail.language];
                                    if (!x_name) {
                                        x_name = specification.name[0];
                                    }
                                }
                                specification.name = x_name;
                                if (specification.is_default_selected) {
                                    specification_price = specification_price + specification.price;
                                }
                            })
                        })

                        let total_item_price = item_data.price + specification_price;
                        item_data.total_item_price = total_item_price;
                        return item_data;
                    });
                    product_data.items = item_array;
                    return product_data;
                });
                this.product_item_list = product_array;
                this.selected_product_id = product_array[0]._id._id;
                this.filtered_product_item_list = product_array;
                this.filter_item("");
                this.delivery_currency = res_data.currency;
                this.user_helper.router_id.user.delivery_currency = res_data.currency;
                this.current_store = res_data.store;
                /*this.current_store.languages_supported.forEach((lang,index) => {
                  if(this.user_helper.router_id.languages[this.user_helper.router_id.main_language].code == lang.code){
                      localStorage.setItem('sub_language', index);
                      if(this.user_helper.router_id.sub_language!=index){
                        this.get_item_list();
                      }
                      this.user_helper.router_id.sub_language = index;
                  }
                })*/


                console.log(this.current_store);
                let store_name = res_data.store.name.replace(/ /g, "-");
                store_name = store_name.toLowerCase();
                let delivery_name = res_data.delivery_name.replace(/ /g, "-");
                delivery_name = delivery_name.toLowerCase();
                let city_name = res_data.city_name.replace(/ /g, "-");
                city_name = city_name.toLowerCase();
                //this.location.replaceState(city_name + '/' + delivery_name + '/' + store_name + '/' + res_data.store._id);

                var height = jQuery('.banner_text').height();
                $('#fix_bar').css('margin-top', ((height / 2) + 10) + 'px');
            } else {
                this.user_helper.data.storage = {
                    "code": res_data.error_code,
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                // this.user_helper.router.navigate(['']);
            }
        });
    }

    close_item_modal() {
        this.current_item = { image_url: [] };
        // this.item_modal.close();
    }


    calculate_is_required() {
        this.current_item.specifications.forEach((specification_group) => {
            if (specification_group.is_required) {
                this.required_count++;
            }
        })
    }

    calculateTotalAmount() {
        if (this.current_item.specifications) {
            this.total = this.current_item.price;
            this.required_temp_count = 0;

            this.current_item.specifications.forEach((specification_group, specification_group_index) => {
                let isAllowed = false;
                var default_selected_count = 0
                specification_group.list.forEach((specification, specification_index) => {

                    if (specification.is_default_selected) {
                        this.total = this.total + specification.price;
                        default_selected_count++;
                    }
                    specification_group.default_selected_count = default_selected_count;
                });

                if (specification_group.type == 1 && specification_group.is_required) {
                    if (specification_group.range) {
                        if (default_selected_count >= specification_group.range) {
                            this.required_temp_count++;
                        }
                    } else {
                        if (default_selected_count >= 1) {
                            this.required_temp_count++;
                        }
                    }
                } else if (specification_group.type == 2 && specification_group.is_required) {
                    if (specification_group.range) {
                        if (default_selected_count >= specification_group.range) {
                            this.required_temp_count++;
                        }
                    } else {
                        if (default_selected_count >= 1) {
                            this.required_temp_count++;
                        }
                    }
                }

            });
            this.total = this.total * this.qty;
        }
        console.log(this.OrderDetail)
        this.OrderDetail.cart_detail.total_cart_price = 0;
        this.OrderDetail.cart_detail.total_item_count = 0;
        this.OrderDetail.cart_detail.total_item_tax = 0;
        this.OrderDetail.cart_detail.order_details.forEach((product) => {
            product.total_item_price = 0;
            product.total_item_tax = 0;
            product.items.forEach((item) => {
                product.total_item_price = product.total_item_price + item.total_item_price;
                product.total_item_tax = product.total_item_tax + item.total_item_tax;
            })
            this.OrderDetail.cart_detail.total_item_count = this.OrderDetail.cart_detail.total_item_count + product.items.length;
            this.OrderDetail.cart_detail.total_cart_price = this.OrderDetail.cart_detail.total_cart_price + product.total_item_price;
            this.OrderDetail.cart_detail.total_item_tax = this.OrderDetail.cart_detail.total_item_tax + product.total_item_tax;
        })
    }

    changeradio(event, specification_group_index, specification_index) {

        var index = this.current_item.specifications[specification_group_index].list.findIndex(x => x.is_default_selected == true)
        if (index !== -1) {
            this.current_item.specifications[specification_group_index].list[index].is_default_selected = false;
        }
        this.current_item.specifications[specification_group_index].list[specification_index].is_default_selected = true;
        this.calculateTotalAmount();
    }

    changecheckbox(event, specification_group_index, specification_index) {

        this.current_item.specifications[specification_group_index].list[specification_index].is_default_selected = event.target.checked;
        this.calculateTotalAmount();

    }

    range(number) {
        let a = [];
        for (let i = 0; i < number; ++i) {
            a.push(i + 1)
        }
        return a;
    }

    filter_item(data) {
        data = data.replace(/^\s+|\s+$/g, '');
        data = data.replace(/ +(?= )/g, '');
        data = new RegExp(data, "gi");

        var product_array = [];
        this.product_item_list.forEach((product) => {

            var item_array = product.items.filter((item_data) => {
                var a = item_data.name.match(data);
                return a !== null;
            })
            if (item_array.length > 0) {
                product_array.push({
                    _id: product._id, name: product.name, store_id: product.store_id, unique_id: product.unique_id,
                    is_visible_in_store: product.is_visible_in_store, items: item_array
                })
            }
        });
        this.filtered_product_item_list = product_array;

        // setTimeout(function () {
        //     jQuery('.iradio').iCheck({
        //         handle: 'radio',
        //         radioClass: 'iradio_square-green'
        //     });
        //
        //     jQuery('.iradio').on('ifChecked', (event) => {
        //
        //         let scroll = (jQuery("#product"+event.target.id).offset().top  - jQuery(".first_div").offset().top + 70);
        //         jQuery("body").animate({
        //             scrollTop: scroll
        //         },1000);
        //
        //     });
        // }, 1000);
    }

    incerase_qty() {
        // if(this.qty < this.current_store.max_item_quantity_add_by_user){
        this.qty++;
        this.calculateTotalAmount();
        // }

    }

    // dragstart_image(event){
    //     this.start_screex = event.screenX
    // }
    // dragend_image(event, length){
    //     this.end_screex = event.screenX;
    //     var a = jQuery('.a.active').attr('data-slide-to');
    //     a = Number(a);
    //     if(this.start_screex < this.end_screex && a > 0){
    //         a = a-1;
    //         jQuery('.a#aa'+a).click()
    //     } else if(this.start_screex > this.end_screex && a < length){
    //         a = a+1;
    //         jQuery('.a#aa'+a).click()
    //     }
    // }

    decrease_qty() {
        if (this.qty > 1) {
            this.qty--;
            this.calculateTotalAmount();
        }
    }

    /*clear_old_cart(){
        this.user_helper.myLoading = true;
        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            cart_unique_token: this.cart_unique_token,
            cart_id: this.user_helper.user_cart.cart_data.cart_id
        }
        this.user_helper.http_post_method_requester(this.user_helper.POST_METHOD.CLEAR_CART, json, (res_data) => {

            if(res_data.success){
                this.user_helper.user_cart.cart_data.cart = [];
                this.user_helper.user_cart.cart_data.selectedStoreId = null;
                this.user_helper.user_cart.cart_data.max_item_quantity_add_by_user = 0;
                this.user_helper.user_cart.cart_data.total_item = 0;
                this.user_helper.user_cart.total_cart_amount = 0;
                // this.clear_cart_modal.close();
                this.addToCart();
            } else {
                this.user_helper.data.storage = {
                    "code": res_data.error_code,
                    "message": this.user_helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
            }
        });
    }*/

    handleClickOnAddToCart() {
        this.addToCart()
        /*  // if(this.current_store.close){
          //     console.log("Store Closed")
          // } else {
          //     if(this.current_location.address == ''){
          //         console.log("your current location is empty")
          //     } else {
  
          if(this.user_helper.user_cart.cart_data.cart.length == 0){
              if(this.current_location.latitude == 0 || this.current_location.longitude == 0){
                  navigator.geolocation.getCurrentPosition((position) => {
  
                      // this.helper.user_cart.cart_data.deliveryLatLng = [position.coords.latitude, position.coords.longitude];
                      this.user_helper.user_cart.destination_address.location = [position.coords.latitude, position.coords.longitude];
  
                      var initialLocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                      var geocoder = new google.maps.Geocoder();
  
                      let request = {latLng: initialLocation};
                      geocoder.geocode(request, (results, status) => {
                          if (status == google.maps.GeocoderStatus.OK) {
                              this.user_helper.ngZone.run(() => {
                                  // this.helper.user_cart.cart_data.deliveryAddress = results[0].formatted_address;
                                  this.user_helper.user_cart.destination_address.address = results[0].formatted_address;
                                  this.user_helper.user_cart.destination_address.city = this.current_location.city1;
                                  this.addToCart()
                              });
                          } else {
                              this.addToCart();
                          }
                      });
                  },(error) => {
                      // this.helper.user_cart.cart_data.deliveryLatLng = this.current_store.location;
                      this.user_helper.user_cart.destination_address.location = this.current_store.location;
  
                      // this.helper.user_cart.cart_data.deliveryAddress = this.current_location.address;
                      this.user_helper.user_cart.destination_address.address = this.current_location.address;
                      this.user_helper.user_cart.destination_address.city = this.current_location.city1
                      this.addToCart()
                  });
              } else {
                  // this.helper.user_cart.cart_data.deliveryLatLng = [this.current_location.latitude, this.current_location.longitude];
                  // this.helper.user_cart.cart_data.deliveryAddress = this.current_location.address;
                  this.user_helper.user_cart.destination_address.location = [this.current_location.latitude, this.current_location.longitude];
                  this.user_helper.user_cart.destination_address.address = this.current_location.address;
                  this.user_helper.user_cart.destination_address.city = this.current_location.city1
  
                  this.addToCart()
              }
  
          } else {
              if(this.user_helper.user_cart.cart_data.selectedStoreId == this.store_id){
                  this.addToCart()
              } else {
                  // this.item_modal.close();
                  // this.clear_cart_modal.open();
                  $('#close_item_model').click();
                  $('#clear_cart_modal').click();
              }
          }*/
    }

    addToCart() {

        this.user_helper.myLoading = true;
        let specificationPriceTotal = 0;
        let specificationPrice = 0;
        let specificationList = [];
        var y_name = "";

        this.current_item.specifications.forEach((specification_group, specification_group_index) => {

            let specificationItemCartList = [];
            specification_group.list.forEach((specification, specification_index) => {
                y_name = specification.name;
                if (typeof specification.name == "object") {
                    y_name = specification.name[this.OrderDetail.cart_detail.language];
                    if (!y_name) {
                        y_name = specification.name[0];
                    }
                }
                specification.name = y_name;
                if (specification.is_default_selected) {
                    specification.name = y_name
                    specificationPrice = specificationPrice + specification.price;
                    specificationPriceTotal = specificationPriceTotal + specification.price;
                    specificationItemCartList.push(specification)
                }
            });
            y_name = specification_group.name;
            if (typeof specification_group.name == "object") {
                y_name = specification_group.name[this.OrderDetail.cart_detail.language];
                if (!y_name) {
                    y_name = specification_group.name[0];
                }
            }
            specification_group.name = y_name;
            if (specificationItemCartList.length > 0) {
                let specificationsItem_json = {
                    list: specificationItemCartList,
                    unique_id: specification_group.unique_id,
                    name: y_name,
                    price: specificationPrice,
                    type: specification_group.type,
                    range: specification_group.range,
                    max_range: specification_group.max_range
                }
                specificationList.push(specificationsItem_json);
            }
            specificationPrice = 0;

        });

        this.cartProductItems.item_id = this.current_item._id;
        this.cartProductItems.unique_id = this.current_item.unique_id;
        this.cartProductItems.item_name = this.current_item.name;
        this.cartProductItems.quantity = this.qty;
        this.cartProductItems.image_url = this.current_item.image_url;
        this.cartProductItems.details = this.current_item.details;
        this.cartProductItems.specifications = specificationList;
        this.cartProductItems.item_price = this.current_item.price;
        this.cartProductItems.total_specification_price = specificationPriceTotal;
        this.cartProductItems.total_item_price = this.total;
        if (this.current_store.is_use_item_tax == true) {
            this.cartProductItems.tax = this.current_item.tax;
        } else {
            this.cartProductItems.tax = this.current_store.item_tax;
        }
        this.cartProductItems.total_specification_tax = specificationPriceTotal * Number(this.cartProductItems.tax) * 0.01;
        this.cartProductItems.item_tax = (Number(this.cartProductItems.tax) * Number(this.cartProductItems.item_price) * 0.01);
        this.cartProductItems.total_tax = (Number(this.cartProductItems.item_tax) + Number(this.cartProductItems.total_specification_tax));
        this.cartProductItems.total_item_tax = (Number(this.cartProductItems.total_tax) * Number(this.cartProductItems.quantity));
        this.cartProductItems.note_for_item = this.note_for_item;


        this.OrderDetail.cart_detail.total_cart_price = this.OrderDetail.cart_detail.total_cart_price + this.total;
        this.OrderDetail.cart_detail.total_item_tax = Number(this.OrderDetail.cart_detail.total_item_tax) + Number(this.cartProductItems.total_item_tax);

        if (this.isProductExistInLocalCart(this.cartProductItems)) {

        } else {
            let cartProductItemsList = [];
            cartProductItemsList.push(this.cartProductItems)
            this.cartProducts.items = cartProductItemsList;
            this.cartProducts.product_id = this.current_item.product_id;
            this.cartProducts.product_name = this.product_name
            this.cartProducts.unique_id = this.product_unique_id
            this.cartProducts.total_item_price = 0;
            this.cartProducts.total_item_tax = 0;
            this.cartProducts.items.forEach((item) => {
                this.cartProducts.total_item_price += item.total_item_price;
                this.cartProducts.total_item_tax += item.total_item_tax;
            });
            this.OrderDetail.cart_detail.order_details.push(this.cartProducts);
            //this.user_helper.user_cart.cart_data.selectedStoreId = this.store_id;
            this.OrderDetail.cart_detail.max_item_quantity_add_by_user = this.current_store.max_item_quantity_add_by_user;


            this.OrderDetail.cart_detail.total_item_count++;



            this.addItemInServerCart(this.OrderDetail.cart_detail.order_details);

        }
    }

    isProductExistInLocalCart(cartProductItems) {

        let bool = false;
        this.OrderDetail.cart_detail.order_details.forEach((cart_item) => {
            if (cart_item.product_id == this.current_item.product_id && bool == false) {
                cart_item.items.push(cartProductItems);
                cart_item.total_item_price = cart_item.total_item_price + this.total;
                this.addItemInServerCart(this.OrderDetail.cart_detail.order_details);
                this.OrderDetail.cart_detail.total_item_count++;
                bool = true;
            }
        });
        return bool;
    }

    addItemInServerCart(cartData) {

        this.cartProductItems = {
            item_id: null,
            unique_id: 0,
            item_tax: 0,
            item_name: '',
            quantity: 0,
            image_url: [],
            details: '',
            specifications: [],
            item_price: 0,
            total_price: 0,
            tax: 0,
            total_specification_price: 0,
            total_specification_tax: 0,
            total_item_price: 0,
            max_item_quantity: 0,
            total_tax: 0,
            total_item_tax: 0,
            note_for_item: ''
        }
        this.cartProducts = {
            items: [],
            product_id: null,
            product_name: '',
            unique_id: 0,
            total_item_price: 0,
            total_item_tax: 0
        }
        //this.user_helper.add_to_cart(null);
        // this.item_modal.close();
        $('#close_item_model').click();
        $('#clear_cart_modal_close').click();
    }


    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'product_pop edit_order_pop with_img' }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    open_edit_item_modal(content2, item, product, selected_item_index, selected_product_index) {

        this.selected_item_index = selected_item_index;
        this.selected_product_index = selected_product_index;

        var product_index = this.product_item_list.findIndex((x) => String(x._id._id) == String(product.product_id));
        var item_index = this.product_item_list[product_index].items.findIndex((x) => String(x._id) == String(item.item_id))

        let current_specification = this.product_item_list[product_index].items[item_index].specifications;

        let order_specification = item.specifications;
        let new_specification = [];
        var x_name = "";
        var y_name = "";
        current_specification.forEach((x) => {
            var index = order_specification.findIndex((order_sp) => order_sp.unique_id == x.unique_id)
            if (index == -1) {
                x_name = x.name;
                if (typeof x.name == "object") {
                    x_name = x.name[this.OrderDetail.cart_detail.language];
                    if (!x_name) {
                        x_name = x.name[0];
                    }
                }
                x.list.forEach((y) => {
                    y_name = y.name;
                    if (typeof y.name == "object") {
                        y_name = y.name[this.OrderDetail.cart_detail.language];
                        if (!y_name) {
                            y_name = y.name[0];
                        }
                    }
                    y.name = y_name;
                })
                x.name = x_name;
                new_specification.push(x);
            } else {
                var new_specification_list = [];
                x.list.forEach((y) => {
                    y_name = y.name;
                    if (typeof y.name == "object") {
                        y_name = y.name[this.OrderDetail.cart_detail.language];
                        if (!y_name) {
                            y_name = y.name[0];
                        }
                    }
                    y.name = y_name;
                    var list_index = order_specification[index].list.findIndex((order_sp_list) => order_sp_list.unique_id == y.unique_id)
                    if (list_index == -1) {
                        y.is_default_selected = false;
                        new_specification_list.push(y);
                    } else {
                        order_specification[index].list[list_index].price = y.price;
                        new_specification_list.push(order_specification[index].list[list_index]);
                    }
                });
                x_name = x.name;
                if (typeof x.name == "object") {
                    x_name = x.name[this.OrderDetail.cart_detail.language];
                    if (!x_name) {
                        x_name = x.name[0];
                    }
                }
                x.name = x_name;
                let json = {
                    list: new_specification_list,
                    "unique_id": x.unique_id,
                    "name": x.name,
                    "is_required": x.is_required,
                    "price": x.price,
                    "type": x.type,
                    "range": x.range,
                    "max_range": x.max_range
                }
                new_specification.push(json);
            }
        });
        console.log(new_specification);
        this.qty = item.quantity;
        this.required_count = 0;
        this.current_item = JSON.parse(JSON.stringify(item));
        this.current_item.price = this.product_item_list[product_index].items[item_index].price;
        this.current_item.specifications = new_specification;
        this.calculate_is_required();
        this.edit_item_calculateTotalAmount();

        setTimeout(() => {
            this.open(content2);
        }, 100);
    }

    edit_item_calculateTotalAmount() {
        this.total = this.current_item.price;
        this.required_temp_count = 0;
        // this.current_item.specifications.forEach((specification_group , specification_group_index) => {
        //     let isAllowed = false;
        //     specification_group.list.forEach((specification , specification_index) => {

        //         if(specification.is_default_selected){
        //             this.total = this.total + specification.price;
        //             if(specification_group.is_required && !isAllowed){

        //                 this.required_temp_count++;
        //                 isAllowed = true;
        //             }
        //         }
        //     });
        // });
        this.note_for_item = this.current_item.note_for_item;
        this.current_item.specifications.forEach((specification_group, specification_group_index) => {
            let isAllowed = false;
            var default_selected_count = 0
            specification_group.list.forEach((specification, specification_index) => {

                if (specification.is_default_selected) {
                    this.total = this.total + specification.price;
                    default_selected_count++;
                }
                specification_group.default_selected_count = default_selected_count;
            });

            if (specification_group.type == 1 && specification_group.is_required) {
                if (specification_group.range) {
                    if (default_selected_count >= specification_group.range) {
                        this.required_temp_count++;
                    }
                } else {
                    if (default_selected_count >= 1) {
                        this.required_temp_count++;
                    }
                }
            } else if (specification_group.type == 2 && specification_group.is_required) {
                if (specification_group.range) {
                    if (default_selected_count >= specification_group.range) {
                        this.required_temp_count++;
                    }
                } else {
                    if (default_selected_count >= 1) {
                        this.required_temp_count++;
                    }
                }
            }

        });

        this.total = this.total * this.qty;
    }
    remove_from_cart(product_index, item_index) {
        this.user_helper.myLoading = true;
        this.OrderDetail.cart_detail.order_details[product_index].items.splice(item_index, 1);
        if (this.OrderDetail.cart_detail.order_details[product_index].items.length <= 0) {
            this.OrderDetail.cart_detail.order_details.splice(product_index, 1);
        }
        this.OrderDetail.cart_detail.total_item_count--;
        this.calculateTotalAmount();
    }
    increase_qty(product_index, item_index) {
        // if(this.user_cart.cart_data.cart[product_index].items[item_index].quantity < this.user_cart.cart_data.max_item_quantity_add_by_user) {
        this.user_helper.myLoading = true;

        this.OrderDetail.cart_detail.order_details[product_index].items[item_index].quantity++;
        let qty = this.OrderDetail.cart_detail.order_details[product_index].items[item_index].quantity;
        let item_price = this.OrderDetail.cart_detail.order_details[product_index].items[item_index].item_price;
        let total_specification_price = this.OrderDetail.cart_detail.order_details[product_index].items[item_index].total_specification_price;

        this.OrderDetail.cart_detail.order_details[product_index].items[item_index].total_item_price = ((item_price + total_specification_price) * qty);

        this.calculateTotalAmount()
        // return true;
        // }
    }

    decrease_qtyy(product_index, item_index) {

        if (this.OrderDetail.cart_detail.order_details[product_index].items[item_index].quantity > 1) {
            this.user_helper.myLoading = true;
            this.OrderDetail.cart_detail.order_details[product_index].items[item_index].quantity--;

            let qty = this.OrderDetail.cart_detail.order_details[product_index].items[item_index].quantity;
            let item_price = this.OrderDetail.cart_detail.order_details[product_index].items[item_index].item_price;
            let total_specification_price = this.OrderDetail.cart_detail.order_details[product_index].items[item_index].total_specification_price;

            this.OrderDetail.cart_detail.order_details[product_index].items[item_index].total_item_price = ((item_price + total_specification_price) * qty);
            this.calculateTotalAmount();


        }

    }
    updateCart() {
        let specificationPriceTotal = 0;
        let specificationPrice = 0;
        let specificationList = [];
        this.current_item.specifications.forEach((specification_group, specification_group_index) => {

            let specificationItemCartList = [];
            specification_group.list.forEach((specification, specification_index) => {

                if (specification.is_default_selected) {

                    specificationPrice = specificationPrice + specification.price;
                    specificationPriceTotal = specificationPriceTotal + specification.price;
                    specificationItemCartList.push(specification)
                }
            });

            if (specificationItemCartList.length > 0) {
                let specificationsItem_json = {
                    list: specificationItemCartList,
                    unique_id: specification_group.unique_id,
                    name: specification_group.name,
                    price: specificationPrice,
                    type: specification_group.type,
                    range: specification_group.range,
                    max_range: specification_group.max_range
                }
                specificationList.push(specificationsItem_json);
            }
            specificationPrice = 0;

        });

        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].item_price = this.current_item.price;
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].total_specification_price = specificationPriceTotal;
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].total_item_price = this.total;
        if (this.current_store.is_use_item_tax == true) {
            this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].tax = this.current_item.tax;
        } else {
            this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].tax = this.current_store.item_tax;
        }
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].total_specification_tax = specificationPriceTotal * Number(this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].tax) * 0.01;
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].item_tax = this.current_item.price * Number(this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].tax) * 0.01;
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].total_tax = this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].item_tax + this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].total_specification_tax;
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].total_item_tax = this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].total_tax * this.qty;
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].note_for_item = this.note_for_item;
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items[this.selected_item_index].specifications = specificationList;

        var total_item_price = 0;
        var total_item_tax = 0;
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].items.forEach((item) => {
            total_item_price = total_item_price + item.total_item_price;
            total_item_tax = total_item_tax + item.total_item_tax;
        });
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].total_item_price = total_item_price;
        this.OrderDetail.cart_detail.order_details[this.selected_product_index].total_item_tax = total_item_tax;

        this.OrderDetail.cart_detail.total_cart_price = 0;
        this.OrderDetail.cart_detail.total_item_count = 0;
        this.OrderDetail.cart_detail.total_item_tax = 0;
        this.OrderDetail.cart_detail.order_details.forEach((product) => {
            this.OrderDetail.cart_detail.total_item_count = this.OrderDetail.cart_detail.total_item_count + product.items.length;
            this.OrderDetail.cart_detail.total_cart_price = this.OrderDetail.cart_detail.total_cart_price + product.total_item_price;
            this.OrderDetail.cart_detail.total_item_tax = this.OrderDetail.cart_detail.total_item_tax + product.total_item_tax;
        })
        $('#close_item_model1').click();
        //this.user_helper.calculateTotalAmount();


    }

    toggleStoreList() {
        $('.store_tab_info_list').toggle();
    }

    get_category_name() {
        let index = this.filtered_product_item_list.findIndex((x) => x._id._id == this.category_id);
        if (index != -1) {
            return this.filtered_product_item_list[index]._id.name;
        } else {
            return ' '
        }
    }


}
