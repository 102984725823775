import { Component, OnInit } from '@angular/core';
import { BooleanService } from '../boolean.service';
import { title, links, placeholder, label, button, description, price, menu_title, orderTable } from '../helper';
import { ViewEncapsulation } from '@angular/core';
import { RatingModule } from "ngx-rating";
import { Helper } from "../user_helper";
declare var jquery: any;
declare var $: any;
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

export interface OrderDetail {
    _id: Object,
    currency: string,
    request_id: string,
    request_status: number,
    store_detail: Object,
    unique_id: number,
    order_status: number,
    order_status_id: number,
    total_time: string,
    delivery_type: number,
    is_user_rated_to_store: Boolean,
    is_user_rated_to_provider: Boolean,
    image_url: any[],
    confirmation_code_for_complete_delivery: string,
    destination_addresses: any[],
    reviews_detail: any[],
    provider_detail: any,
    pickup_addresses: any[],
    store_id: any,
}

export interface StoreDetail {
    image_url: string,
    name: string
}

export interface ProviderDetail {
    _id: Object,
    first_name: string,
    image_url: string,
    last_name: string,
    location: any[]
}

@Component({
    selector: 'app-order-detail',
    templateUrl: './order-detail.component.html',
    styleUrls: ['./order-detail.component.css'],
    providers: [Helper]
})
export class OrderDetailComponent implements OnInit {

    public title: any = title;
    public links: any = links;
    public placeholder: any = placeholder;
    public label: any = label;
    public button: any = button;
    public description: any = description;
    public price: any = price;
    public menu_title: any = menu_title;
    public orderTable: any = orderTable;
    rating: number = 1;
    type: number = 1;
    model;
    model2;

    OrderDetail: OrderDetail;
    StoreDetail: StoreDetail;
    ProviderDetail: ProviderDetail;
    destination_address: any = {};
    order_payment: any = {};

    private user_id: string = '';
    private closeResult: string = '';
    private review: string = '';
    private server_token: string = '';
    private order_id: string = '';
    private total_item: number = 0;
    private order_total: number = 0;
    is_confirmation_code_required_at_complete_delivery: boolean = false;

    selected_screen: number = 1;

    old_lat_lng: any[];
    new_lat_lng: any[];

    ORDER_STATE: any;
    date_time: any[] = [];
    ORDER_STATE_ID: any;
    status: any;
    order_detail: any[] = [];
    map: any;
    interval: any;
    order_interval: any;
    delivery_currency: string = '';
    numDeltas: number = 100;
    delay: number = 100;
    i: number = 0;
    map_loaded: Boolean = false;

    accepted_time: any = '';
    provider_accepted_time: any = '';
    picked_time: any = '';
    picked_image: any = '';
    arrived_image: any = '';
    ready_time: any = '';
    deliveryman_on_the_way_time: any = '';

    show_code: Boolean = false;
    is_user_pick_up_order: Boolean = false;
    cancel_reason: string = '';
    user_type: number = 0;

    total_cart_price: number = 0;
    total_item_tax: number = 0;

    constructor(public bln: BooleanService, private modalService: NgbModal, public helper: Helper) { }
    ngOnDestroy() {
        clearInterval(this.interval);
        clearInterval(this.order_interval);
    }

    ngOnInit() {

        this.bln.showLogin = false;
        this.bln.showhedmid = true;
        this.bln.showcart = true;
        this.bln.isSticky = false;
        this.bln.showLink = false;
        this.bln.isHome = true;
        this.bln.cart = true;
        this.bln.showSignup = true;
        this.bln.isAdd_product = true;
        this.bln.isShop = true;
        this.bln.isInner_page = true;
        this.bln.isGreen = true;
        this.bln.isBod_btm_h = false;
        this.bln.isOrd_b = true;

        this.status = this.helper.status;
        this.ORDER_STATE = this.helper.ORDER_STATE;
        this.ORDER_STATE_ID = this.helper.ORDER_STATUS_ID;

        this.OrderDetail = {
            _id: null,
            request_id: null,
            provider_detail: null,
            request_status: 0,
            currency: '',
            store_detail: {},
            unique_id: 0,
            order_status: 0,
            delivery_type: 1,
            order_status_id: 0,
            is_user_rated_to_store: false,
            is_user_rated_to_provider: false,
            image_url: [],
            total_time: '',
            confirmation_code_for_complete_delivery: '',
            destination_addresses: [],
            reviews_detail: [],
            pickup_addresses: [],
            store_id: ''
        }

        this.ProviderDetail = {
            _id: null,
            first_name: '',
            image_url: '',
            last_name: '',
            location: []
        }

        this.StoreDetail = {
            name: '',
            image_url: ''
        }

        this.helper.message();
        this.helper.myLoading = true;


        let user = JSON.parse(localStorage.getItem('user'));
        if (user && user._id) {
            this.user_id = user._id;
            this.server_token = user.server_token;
            this.user_type = user.user_type;
        } else {
            this.helper.router.navigate(['deliveries']);
        }
        this.order_id = this.helper.router_id.user.order_id;

        this.get_order_detail(1);
        this.order_interval = setInterval(() => {
            this.get_order_detail(2);
        }, 5000)
    }

    cancel_order() {
        this.helper.ngZone.run(() => {
            $('.iradio').iCheck({
                handle: 'radio',
                radioClass: 'iradio_square-green'
            });
            $('#default_reason').iCheck('check');
            this.cancel_reason = $('#default_reason').val();
            $('.iradio').on('ifChecked', (event) => {

                if (event.target.value == 1) {
                    $('#text_box').show();
                    this.cancel_reason = '';
                } else {
                    $('#text_box').hide();
                    this.cancel_reason = event.target.value;
                }
            });
        });
        // this.cancel_order_modal.open();
    }

    set_cancel_reason(event) {
        this.cancel_reason = event.target.value;
    }

    cancel_order_service() {

        if (this.cancel_reason !== '') {

            let json = {
                user_id: this.user_id,
                server_token: this.server_token,
                order_id: this.order_id,
                order_status: this.OrderDetail.order_status,
                cancel_reason: this.cancel_reason
            }

            this.helper.http_post_method_requester(this.helper.POST_METHOD.USER_CANCEL_ORDER, json, (res_data) => {

                this.helper.myLoading = false;
                if (res_data.success) {
                    this.helper.data.storage = {
                        "message": this.helper.MESSAGE_CODE[res_data.message],
                        "class": "alert-info"
                    }
                    this.helper.router.navigate(['orders']);
                } else {
                    this.helper.data.storage = {
                        "message": this.helper.ERROR_CODE[res_data.error_code],
                        "class": "alert-danger"
                    }
                    this.helper.router.navigate(['orders']);
                }
            });
        }
    }


    give_rate_modal(type, content) {
        this.rating = 1;
        this.review = '';
        this.type = type;
        console.log(this.OrderDetail);
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'product_pop with_img', centered: true }).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }
    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }
    give_rate() {
        if (this.type == 1) {
            let json = {
                user_id: this.user_id,
                order_id: this.order_id,
                server_token: this.server_token,
                user_rating_to_provider: this.rating,
                user_review_to_provider: this.review,
            }
            this.helper.http_post_method_requester('api/user/rating_to_provider', json, (res_data) => {
                this.get_order_detail(1);
                $('#close_rate').click();
            })
        } else {
            let json = {
                user_id: this.user_id,
                order_id: this.order_id,
                server_token: this.server_token,
                user_rating_to_store: this.rating,
                user_review_to_store: this.review,
            }
            this.helper.http_post_method_requester('api/user/rating_to_store', json, (res_data) => {
                this.get_order_detail(1);
                $('#close_rate').click();
            })
        }
    }
    get_order_detail(n) {

        let json = {
            user_id: this.user_id,
            server_token: this.server_token,
            order_id: this.order_id
        }
        this.helper.http_post_method_requester(this.helper.POST_METHOD.ORDER_HISTORY_DETAIL, json, (res_data) => {
            this.helper.myLoading = false;

            if (res_data.success) {
                this.order_detail = res_data.cart_detail.order_details;
                this.destination_address = res_data.cart_detail.destination_addresses[0];
                this.OrderDetail = res_data.order_list;
                this.OrderDetail.currency = res_data.currency;
                this.delivery_currency = res_data.currency;
                this.order_payment = res_data.order_payment;
                if (res_data.order_list.request_id !== null && res_data.request_detail) {
                    this.OrderDetail.request_status = res_data.request_detail.delivery_status
                } else {
                    this.OrderDetail.request_status = 0;
                }
                this.OrderDetail.destination_addresses = res_data.cart_detail.destination_addresses
                this.OrderDetail.pickup_addresses = res_data.cart_detail.pickup_addresses
                this.total_item_tax = res_data.cart_detail.total_item_tax;
                this.total_cart_price = res_data.cart_detail.total_cart_price;
                this.StoreDetail = res_data.store_detail;
                this.is_user_pick_up_order = res_data.order_payment.is_user_pick_up_order;
                this.is_confirmation_code_required_at_complete_delivery = false;

                this.date_time = res_data.order_list.date_time;
                if (res_data.request_detail) {
                    res_data.request_detail.date_time.forEach((date) => {
                        this.date_time.push(date);
                    })
                }

                this.is_user_pick_up_order = res_data.order_payment.is_user_pick_up_order;
                var accepted_index = this.date_time.findIndex((date) => date.status == this.ORDER_STATE.STORE_ACCEPTED);
                if (accepted_index !== -1) {
                    this.accepted_time = this.date_time[accepted_index].date;
                }
                var ready_index = this.date_time.findIndex((date) => date.status == this.ORDER_STATE.OREDER_READY);
                if (ready_index !== -1) {
                    this.ready_time = this.date_time[ready_index].date;
                }
                var deliveryman_on_the_way_index = this.date_time.findIndex((date) => date.status == this.ORDER_STATE.DELIVERY_MAN_PICKED_ORDER);
                if (deliveryman_on_the_way_index !== -1) {
                    this.deliveryman_on_the_way_time = this.date_time[deliveryman_on_the_way_index].date;
                }

                var provider_accepted_index = this.date_time.findIndex((date) => date.status == this.ORDER_STATE.DELIVERY_MAN_ACCEPTED);
                if (provider_accepted_index !== -1) {
                    this.provider_accepted_time = this.date_time[provider_accepted_index].date;
                }

                var picked_index = this.date_time.findIndex((date) => date.status == this.ORDER_STATE.DELIVERY_MAN_PICKED_ORDER);

                if (picked_index !== -1) {

                    this.picked_time = this.date_time[picked_index].date;
                    this.picked_image = this.date_time[picked_index].image_url;
                }

                var picked_index = this.date_time.findIndex((date) => date.status == this.ORDER_STATE.DELIVERY_MAN_ARRIVED_AT_DESTINATION);
                if (picked_index !== -1) {
                    this.arrived_image = this.date_time[picked_index].image_url;
                }

                if (res_data.order_list.request_id) {
                    this.OrderDetail.request_status = res_data.request_detail.delivery_status
                } else {
                    this.OrderDetail.request_status = 0;
                }
                // if(this.OrderDetail.order_status_id == this.helper.ORDER_STATUS_ID.CANCELLED || this.OrderDetail.order_status_id == this.helper.ORDER_STATUS_ID.REJECTED){
                //     this.helper.router.navigate(['orders']);
                // } else {

                let time = res_data.order_payment.total_time * 60;
                let hours = Math.floor(time / 3600)
                let minute = Math.floor((time % 3600) / 60);

                this.OrderDetail.total_time = hours + ' hr ' + ': ' + minute + ' min';

                if (n == 1) {
                    this.order_detail.forEach((product) => {
                        product.items.forEach((item) => {
                            this.total_item = this.total_item + 1;
                            this.order_total = this.order_total + item.total_item_price;
                        })
                    });
                }

                // if (this.OrderDetail.request_status == this.ORDER_STATE.ORDER_COMPLETED) {
                //     this.helper.router.navigate(['order/invoice']);
                // }
                // }

            } else {
                this.helper.data.storage = {
                    "message": this.helper.ERROR_CODE[res_data.error_code],
                    "class": "alert-danger"
                }
                this.helper.router.navigate(['orders']);
            }
        });

    }

    get_item_data() {
        this.selected_screen = 1;
    }
    get_deliveryman_data() {

        if (this.OrderDetail.request_status >= this.ORDER_STATE.DELIVERY_MAN_STARTED_DELIVERY && this.OrderDetail.request_status < this.ORDER_STATE.ORDER_COMPLETED) {
            this.selected_screen = 2;
        }
    }

    clear_old_cart(clear_cart_modal) {
        this.helper.user_cart.cart_data.cart = [];
        $('#clear_cart_modal_close').click();
        this.reorder(clear_cart_modal);
    }

    open(content) {
        this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', windowClass: 'product_pop with_img' }).result.then((result) => {

        }, (reason) => {
        });
    }

    reorder(clear_cart_modal) {
        if (this.helper.user_cart.cart_data.cart.length > 0) {
            $('#clear_cart_modal').click();
            this.open(clear_cart_modal);
        } else {
            let json = {
                user_id: this.user_id,
                server_token: this.server_token,
                user_type: this.user_type,
                store_id: this.OrderDetail.store_id,
                cart_unique_token: this.helper.cart_unique_token,
                order_details: this.order_detail,
                destination_addresses: this.OrderDetail.destination_addresses,
                pickup_addresses: this.OrderDetail.pickup_addresses,
                total_item_tax: this.total_item_tax,
                total_cart_price: this.total_cart_price
            }
            console.log(json)
            this.helper.http_post_method_requester(this.helper.POST_METHOD.ADD_ITEM_IN_CART, json, (res_data) => {

                if (res_data.success) {

                    this.helper.router_id.user.currency = this.helper.router_id.user.delivery_currency;
                    this.helper.user_cart.cart_data.cart_id = res_data.cart_id;
                    this.helper.user_cart.cart_data.city_id = res_data.city_id;
                    this.helper.data.storage = {
                        "message": this.helper.MESSAGE_CODE[res_data.message],
                        "class": "alert-info"
                    }
                } else {
                    this.helper.data.storage = {
                        "message": this.helper.ERROR_CODE[res_data.error_code],
                        "class": "alert-danger"
                    }
                }
                this.helper.router.navigate(['']);
                this.helper.get_cart();

                this.helper.message();
                this.helper.myLoading = false;

            });

        }
    }

}
