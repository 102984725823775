import { Component, OnInit } from '@angular/core';
import { label,menu_title } from '../helper';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
	public label:any = label;
	public menu_title:any = menu_title;
  constructor() { }

  ngOnInit() {
  }

}
