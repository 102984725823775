export interface Theme {
	name: string;
	properties: any;
  } 

export const light: Theme = {
    name: "light",
    properties: {
		
		"--direction":"ltr",
		"--float":"left",
		"--text-align":"left",
		"--anti-text-align":"right",
		"--anti-float":"right",
		"--adapp":"0%",
		"--right":"0",
		"--left":"none",
		"--profile-image":"15px",
		"--wallet-left":"5px",
		"--wallet-right":"0px",
		"--dl_dt-left":"79px",
		"--dl_dt-right":"none",
		"--fav-left":"none",
		"--fav-right":"10px",
		"--doted-right":"0px",
		"--doted-left":"none",

		// "--foreground-default":"",   
		// "--background-white":"#ffffff", 
		// "--background-light":"#fbfbfb",
		// // "--background-gray":"#f6f5f3",
		// "--background-gray-light":"#fbfbfb",
		// "--background-green":"#16ce86",

		// "--border-green":"#1+6ce86",
		// "--border-gray":"#8d8d8d",
		// "--border-gray-light":"#e5e5e5",
		// "--border-black":"#000000",
		// "--border-white":"#ffffff",

		// "--text-white":"#ffffff",
		// "--text-black":"#00000",
		// "--text-gray":"#8d8d8d",
		// "--text-red":"#de664e",
		// "--text-green":"#16ce86",		
		
		// "--button-background":"#16ce86",
		// "--button-text":"#ffffff",

    }
};
  
	// private colorService: ColorService
	// this.colorService.setLightTheme();
	// color = new ColorService();
	// import { ColorService } from '../color.service';
