import { Component } from '@angular/core';
import { Theme, light } from './theme';
import { PlatformLocation } from '@angular/common';
declare var $: any;
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	private active: Theme = light;
	language_list: any[] = [];

	constructor(private platformLocation: PlatformLocation) {

	}
	ngOnInit() {
		this.platformLocation.onPopState(() => {
			$('body').find('ngb-modal-window').remove();
			$('body').find('ngb-modal-backdrop').remove();
		});
		this.active.properties = {

			"--direction": "ltr",
			"--float": "left",
			"--text-align": "left",
			"--anti-text-align": "right",
			"--anti-float": "right",
			"--adapp": "0%",
			"--right": "0",
			"--left": "none",
			"--profile-image": "15px",
			"--wallet-left": "5px",
			"--wallet-right": "0px",
			"--dl_dt-left": "79px",
			"--dl_dt-right": "none",
			"--fav-left": "none",
			"--fav-right": "10px",
			"--doted-right": "0px",
			"--doted-left": "none",

		}
		localStorage.setItem('code', 'en');
		if (localStorage.getItem('all_languages')) {
			this.language_list = JSON.parse(localStorage.getItem('all_languages'));
			if (!localStorage.getItem('main_language')) {
				var main_language = 0
			} else {
				var main_language = Number(localStorage.getItem('main_language'));
			}
			if (this.language_list[main_language].code == "ar") {
				this.active.properties = {

					"--direction": "rtl",
					"--float": "right",
					"--text-align": "right",
					"--anti-text-align": "left",
					"--anti-float": "left",
					// "--adapp":"44%",
					"--adapp": "35%",
					"--right": "none",
					"--left": "10px",
					"--profile-image": "-15px",
					"--wallet-left": "0px",
					"--wallet-right": "5px",
					"--dl_dt-left": "none",
					"--dl_dt-right": "79px",
					"--fav-left": "10px",
					"--fav-right": "none",
					"--doted-right": "none",
					"--doted-left": "0px",
				}
				localStorage.setItem('code', 'ar');
			}
		}
		Object.keys(this.active.properties).forEach(property => {

			document.documentElement.style.setProperty(
				property,
				this.active.properties[property]
			);
		});
	}
	onActivate(event) {
		window.scroll(0, 0);
	}
}
