export var button = {
    signup: "Sign Up",
    signin: "Sign In",
    give_rate: "Give Rate",
    ok: "Ok",
    as_soon_as_possible: "as soon as possible",
    login_b: "Login",
    japenese: "japenese",
    sushi: "Sushi",
    Confirm: "Confirm",
    valider: "valider",
    order: "ORDER",
    order_now: 'ORDER NOW',
    apply: "Apply",
    add_to_cart: "Add to Cart",
    other: "Other",
    edit: "Edit",
    payment: "Payment",
    track_your_order: "Track your order",
    login: 'Login',
    modify: 'modify',
    remove: 'remove',
    privacy_policy: 'Privacy policy',
    terms_and_condition: 'Terms And Conditions',
    agree: 'By continuing, you agree our',
    add_an_adress: "Add an adress",
    add_a_card: "Add a card",
    reset: "Reset",
    save_b: "Save",
    submit: "SUBMIT",
    search_b: "search",
    pay: 'Pay',
    reorder: 'reOrder',
    select: 'Select'
}

export var language = {

}

export var placeholder = {
    first_name: 'First Name',
    last_name: 'Last Name',
    pickup_note: 'Pickup Note',
    delivery_note: 'Delivery Note',
    phone: 'Phone',
    country: 'Country',
    referral_code: 'Referral Code',
    email_address: "Email address",
    enter_an_address: "Enter an address",
    // search: "search for a restaurant, types of food, flowers, medecines or grocery ...",
    search: "Search for restaurant",
    name: "Name",
    surame: "Surname",
    email: "Email",
    password: "Password",
    number: "Number",
    email_phone: "Email or phone number",
    write_something: "Write something",
    company_name: "company name",
    postal_code: "Postal Code",
    surname: "Surname",
    name_it: "Name it",
    delivery_address: "Delivery Address",
    billing_address: "Billing Address",
    floor: "Floor",
    entry_code: "Entry code",
    comment_for_the_hopper: "Comment for the deliveryman",
    promo_code: "Promo code",
    from: "From",
    to: "To",
    new_password: 'New Password',
    city: 'City',
    state: 'State'


}

export var menu_title = {
    follow_us: "Follow US",
    ORDER: "ORDER",

    order_f: "order",
    wallet_history: "Wallet History",
    partner: "Be a partner",
    hopper: "be a deliveryman",
    team: "Crave Tulum team",
    help: "Help",
    terms_conditions: "terms & conditions",
    partners: "partners",
    hoppers: "Deliverymans",
    hop: "Crave Tulum ?",
    order: "order !",
    profile: "Profile",
    orders: "Orders",
    favorites: "Favorites",
    logout: "Logout",
    salads_soup: "Salads & soup",
    nigiri: "Nigiri",
    futomaki: "Futomaki",
    sashimi: "Sashimi",
    gunkan: "Gunkan",
    chirashi: "Chirashi",
    uramaki: "Uramaki",
    assortments: "Assortments",
    restaurants: "Restaurants",
    pharmacies: "pharmacies",
    florists: "florists",
    grocery_stores: "grocery stores",
    breakfast: "Breakfast",
    addresses: 'Addresses',
    payments: 'Payments'

}

export var title = {
    valid_phone:"Enter Valid Phone No",
    app_name: "Crave Tulum",
    description: "With the largest on-demand network in the industry, you can explore your city, find its hidden hotspots, and watch as we bring your new favorite right to your door. Order food from your local restaurants.Get food delivered in under an hour so you can spend your time living your best life. Whether you need a steak or a cup of latte from Starbucks, we deliver everything.We deliver more than just lunch.No hassle, just fast service. Send anything from one address to another. We pickup anything from documents to bags, and deliver in no time.Download the app for iOS or Android for free.",
    copyright: 'Copyright',
    email_otp_wrong: 'Email Otp Wrong',
    sms_otp_erong: 'Sms Otp Werong',
    is_user_pick_up_order: 'User Pickup Order',
    otp_wrong: 'Otp Wrong',
    verification: 'Verification',
    tip_amount: 'Tip Amount',
    tip_per: 'Tip%',
    offer_tip: 'Would you like to offer a TIP?',
    confirmation_code: "Confirmation Code",
    sms_otp: "Sms Verification Code",
    email_otp: "Email Verification Code",

    contactless_delivery: 'Contactless Delivery',
    footer: '2021 Crave Tulum. All Rights Reserved',
    images: 'Images',
    rating: 'Rating',
    we_want: 'We want to hear from you !',
    app_store: 'APP STORE',
    play_store: 'PLAY STORE',
    goto_store: 'GO TO STORE',
    content1: 'Discover The Best',
    content2: 'DELIVERY SERVICE',
    content3: 'You choose & we will delivery it at your doorstep.',
    content4: 'Any restaurant, any meal',
    officeIn: 'Office in',
    footerlogocontent1: 'We do what we',
    footerlogocontent2: 'love, and we love',
    footerlogocontent3: 'what we do.',
    header_1: 'TO ORDER',
    header_2: 'Download the App!',
    header_3: 'DOWNLOAD NOW!',
    delivery_1: 'Explore the Deliveries',
    delivery_2: 'Choose any delivery from below',
    select_delivery_address: 'Select Delivery Address',
    courier: 'Courier',
    product_group: 'Category',
    choose_delivery_vehicle: 'Choose Delivery Vehicle',
    delivery_time: 'Delivery Time',
    partner_message: "Thank you for contacting. We will get back to you shortly.",
    hopper_message: "Thank you for contacting. We will get back to you shortly.",
    other_store_item_in_cart: 'You have Items from another store in your cart.To continue you must clear your cart',
    attention: 'Attention',
    busy: 'Busy',
    invoice: 'Invoice',
    no_delivery_found_this_area: 'no delivery found in this area',
    schedule_time_error: 'Schedule Time Create After',
    store_not_provide_schedule_order: 'Store Not Provide Schedule Order',
    please_accept_terms_cond_signup: 'Please accept terms and condition to sign up',
    alert: 'Alert',
    invalid_amount: 'The configuration allow you to specify a minimum amount, that order total are required to meet',
    no_article_selected: 'There is no article selected yet',
    check_your_email: 'CHECK YOUR EMAIL',
    forgot_password_success_message: "If we have this email on file, you’ll receive instructions to reset your password. Don’t forget to look through your spam folder!",
    basket_empty: 'Your basket is empty!',
    go_to_shop: 'Go to the shop to place your order',
    successfully_subscribe: 'THANKYOU FOR SUBSCRIPTION',
    clear_all: 'clear all',
    filter_applied: 'Filter Applied',
    newsletter: "subscribe to the newsletter",
    as_soon_as_possible: "As soon as possible",
    schedule_a_delivery: "Schedule a delivery",
    sign_up: "SIGN UP",
    acount: "Already have an account?",
    login: "Login",
    sushi_shop: "Sushi shop",
    soupe_miso: "Soupe miso",
    customize_your_soupe: "Customize your soupe",
    add_notes: "Add notes",
    quantity: "Quantity",
    avantages: "avantages",
    hop_a_votre_service: "Crave Tulum à votre service",
    partner_title: "Become <br />a partner",
    Join_the_communauty: "Join The <br />communauty",
    bienvenue_alexandre: "WELCOME",
    future_partner: "Future partner ?",
    future_hopper: "Futur deliveryman ?",
    team: "TEAM",
    your_basket: "Your basket",
    date: "Date",
    delivery_details: "Delivery details",
    use_an_address: "Use an address",
    use_your_position: "Use your position",
    order_details: "order details",
    subtotal: "Subtotal",
    delivery_fee: "Delivery fee",
    total: "Total",
    help_t: "Help",
    required: "Required",
    add_an_ingredient: "Add an ingredient",
    how_do_you_feel_today: "How do you feel today",
    hop_got_your_back: "Crave Tulum got your back",
    search_label: "Find your favourite shop next to you !",
    payment: "Payment",
    credit_card: "Credit card",
    schedule_an_order: "schedule an order",
    thank_you: "Thank you",
    order_accepted: "Order accepted",
    order_picked: 'Order Picked',
    hopper_selected: "Deliveryman selected",
    order_prepared: "Order prepared",
    hopper_on_the_way: "Deliveryman on the way",
    order_received: "Order received",
    order_completed: "Order completed",
    denise_burger: "Denise’s burger",
    vincent_moret: "VINCENT MORET",
    profile_t: "Profile",
    addresses: "Addresses",
    payments: "Payments",
    credit_cards: "Credit cards",
    wallet: "Wallet",
    current_balance: "Current balance",
    currents_orders: "Currents orders",
    burgers_co: "BURGER’S CO",
    quick_restaurant: "QUICK RESTAURANT",
    lentrecote: "L’ENTRECÔTE",
    wok_25: "WOK 25",
    denises_burger: "Denise’s burger",
    chicken_dominator: "Chicken Dominator",
    choose_your_crust: "Choose your crust",
    veg_toppings: "Veg Toppings",
    order_history: "Order history",
    yesterday: "Yesterday",
    macdonalds: "MCDONALD’S",
    chezz_witt: "CHEZ WITT",
    favourites: "Favourites",
    coucou: "COUCOU",

    service_tax: 'Service Tax',
    service_price: 'Service Price',
    promo_bonus: 'Promo Bonus',
    total_service_cost: 'Total Service Price',
    item_price: 'Item Price',
    item_tax: 'Item Tax',
    total_item_cost: 'Total Item Price',
    promocode: 'Promo code',
    manual: 'manual',
    social: "social",
    cash: 'Cash',
    card: 'Card',
    fr: "FR",
    en: "EN",
    show_filter: 'show filter',
    hide_filter: 'hide filter',

    this_field_is_required: 'This field is required',
    please_enter_valid_email: 'Please Enter Valid Email',
    password_doesnt_seem_right: 'Your password is too short. Please use a password with at least 6 characters.',
    please_enter_valid_email_phone: 'Please enter valid email or phone',
    forgot_password: 'Forgot password',
    invalid_date_time: 'Invalid Date & Time',

    address_name: 'Address Name',
    address: 'Address',
    comment: 'Comment',
    entry_code: 'Entry Code',
    floor: 'Floor',
    amount: 'Amount',
    card_holder_name: 'Card Holder Name',
    card_number: 'Card Number',
    expiry_month: 'Expiry Month',
    expiry_year: 'Expiry Year',
    cvv: 'cvv',
    add_Card: 'Add Card',
    min: 'min',
    max: 'max',
    order_no: 'Order No.',
    you_are_paying: 'You are paying',
    amount_from_wallet_balance: 'Amount from wallet balance',
    you_should: 'You should pay',
    cash_delivery_arrived_your_doorstep: 'cash when delivery arrived your doorstep',
    how_would_like_pay: 'How would like to pay?',

    reopen_at: 'Reopen At',
    store_closed_today: 'Store Closed Today',
    store_closed: 'Store Closed',
    closed: 'CLOSED',

    cancel_reason1: 'Missing item from my order.',
    cancel_reason2: 'Delivery address is incorrect.',
    cancel_reason3: 'Other',
    cancellation_reason: 'Cancellation Reason',
    get_code: 'Get Code',

    pickup: "Pick up",
    destination: 'Destination',
    please_add_card_first: 'Please Add Card First',
    pickup_details: 'Pickup Details',
    delivery_title: 'Deliveries in ',
    ads_title: 'ads running on your area',
    delivery_date: 'Select a Delivery Date',
    desired_delivery_time: 'Desired Delivery Time',
    select_first_delivery_address: 'Please select address first for delivery',
    not_delivery_address: 'We are not delivering your address',
    chat_with_store: 'Chat With Store',
    chat_with_provider: 'Chat With Provider',
    chat_with_admin: 'Chat With Admin',
    admin: 'Admin',
    provider: 'Provider',
}

export var label = {
    help1: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor and vitality, so that the labor and sorrow, some important things to do eiusmod. Over the years, I will come, who will nostrud aliquip out of her the advantage of exercise, so that stimulus efforts if the school district and longevity. Want to be a pain in the cupidatat cillum has been criticized in the Duis et dolore magna flee produces no resultant pleasure. Excepteur cupidatat blacks are not excepteur, is soothing to the soul, that is, they deserted the general duties of those who are to blame for your troubles.",
    help_title1: "HOW TO INSTALL THE APPLICATION?",
    help_title2: "WHAT CAN I ORDER?",
    help_title3: "HOW MUCH DOES THE SERVICE COST ?",
    help_title4: "WHERE CAN I BE DELIVERED?",
    help_title5: "HOW IS THE PAYMENT MADE?",
    help_title6: "HOW ARE THE DELIVERY COSTS CALCULATED?",
    help_description1: "Download The App Directly From Your Smartphone On The Play Store (Android) And The App Store (IOS).",
    help_description2: "You Can Order The Whole Assortment From Restaurants, Supermarkets, Drugstores And Florists Who Have Joined The Crave Tulum Adventure.",
    help_description3: "You Pay Only For The Selected Products As Well As The Delivery Costs To Remunerate Your Deliveryman.",
    help_description4: "Crave Tulum Is Currently Available Anywhere In Geneva! Whether you are at the lake, at home or in the office, you will always be delivered!",
    help_description5: "Payment Is Made Securely By Credit Card (MasterCard Or Visa).",
    help_description6: "Delivery charges are calculated on the distance between the point of collection and the point of delivery.",
    date: "Date",
    hour: "Hour",
    pickle_slices: "Pickle slices",
    grandma: "GRANDMA",
    leane: "LéANE",
    command_carried_out_on_the: "Command carried out on the",
    planned_order_on_the: "Planned order on the",
    delivery_performed_on_the: "Delivery performed on the"
}

export var price = {
    sub_price: "CHF 30.-",
    del_fee: "CHF 5.-",
    total_price: "CHF 35.-",
    shop_total: "CHF 50.-",
    cur_bal: "CHF 160.-"
}

export var option = {
    japenese: "japenese"
}

export var service = {
    performance: "performance",
    dynamisme: "dynamisme",
    flexibilite: "flexibilité"
}

export var description = {
    service_dis: "Avec nos partenaires, nous mettons en place le système  le plus <br />adapté  afin qu’ils puissent se concentrer sur ce qu’ils savent <br />faire de mieux",
    contact_us_dis: "<span>un projet de livraison ?</span>partagez votre idée et <br />réalisons la ensemble !",
    shop_dis: "Delivery time - 30 mins until 8:30 PM",
    pop_p: "Lorem ipsum dolor sit amet, consectetur <br />dipisicing elit, sed do eiusmod tempor",
    welcome_dis: "Passez votre première commande dès maintenant",
    wel_hop_des: "Le Deliveryman manager t’enverra un mail très prochainement <br />pour te donner de plus amples informations.",
    soc_des: "En attendant, suis-nous sur nos réseaux !",
    wel_part_des: "<span>Le responsable partenaire</span> prendra contact avec vous <br />très prochainement. Nous nous réjouissons de <br />vous rencontrer !",
    copyright: "© 2018",
    faq_des: "Crave Tulum est votre",
    faq_span: "nouveau service de livraison",
    faq_span2: "la région genevoise",
    faq_des2: "crée par des jeunes de",
    faq_des3: "pour les Genevois. Notre équipe met constamment tout en oeuvre pour améliorer l’application et vous remercie de votre soutien. En cas de question vous pouvez nous contacter à",
    faq_des4: "ou directement",
    au: "au",
    thakyou_p: "Your order is placed and will be delivered soon",
    payment_des: "From your registered credit card above, you can directly <br />add money to your wallet. Then you can use it as a <br />payment method when your order.",
    bc: "bc",
    qr: "qr",
    en: "en",
    wo: "wo",
    wheat_thin_crust: "Wheat thin crust",
    veg_toppings: "Fresh Tomato<br /> Golden Corn <br />Onion",
    del_des: "Rue des Charmilles 1, 1203 Genève <br />Floor : 2<span>Entry code : 1234</span>Comment for the Deliveryman : <br />Left door",
    payment_des2: "Only VISA, Master Card and <br />Postcard are accepted"
}

export var links = {
    hop: "Crave Tulum",
    promo_code: "Promo code",
    view_details_of_your_order: "View details of your order",
    call_the_hopper: "Call the Deliveryman",
    edit: "Edit",
    add: "+ Add",
    order_accepted: "Order accepted",
    cancel_order: "Cancel order",
    hopper_selected: "Deliveryman selected",
    hopper_on_the_way: "Deliveryman on the way",
    order_received: "Order received",
    canceled: "Canceled",
    save: "Save",
    delete_address: "Delete Address",
    delete_a_card: "Delete a card",
    delete_section: "Delete Section"

}

export var slider_conList = [{
    "slide_img": "../assets/images/slide-1.jpg",
    "app_icon": "../assets/images/app_icon.png",
    "slide_title": "Crave Tulum",
    "slide_des": "Crave Tulum is a service that you deliver <br /><span>what you want, when you 			   want</span> and <br /><span>wherever you are !</span>",
    "dwn_app": "Download the app now !",
    "app_btn": "../assets/images/apple_btn.png",
    "play_btn": "../assets/images/play_btn.png"
}
]

export var tabList = [{
    title: "Hungry !",
    tabimg: "../assets/images/hungry.png",
    buttons: [{ "button": "Burger" }, { "button": "pizza" }, { "button": "pasta" }, { "button": "Kebab" }, { "button": "Sandwich" }, { "button": "japenese" }, { "button": "Lebanese" }, { "button": "Italian" }, { "button": "indian" }, { "button": "meat" }, { "button": "fish" }, { "button": "salad" }, { "button": "desserts" }]
},
{
    title: "Seek !",
    tabimg: "../assets/images/seek.png",
    buttons: [{ "button": "Allergy" }, { "button": "Pains and fever" }, { "button": "Cold" }, { "button": "Stomach ache" }, { "button": "condoms" }, { "button": "bandages & burns" }, { "button": "vitamines" }, { "button": "relaxations & sleep" }, { "button": "babies" }]
},
{
    title: "la vie en rose !",
    tabimg: "../assets/images/rose.png",
    buttons: [{ "button": "red roses" }, { "button": "orange roses" }, { "button": "white roses" }, { "button": "pink roses" }, { "button": "bouquets" }, { "button": "floral arrangements" }, { "button": "plants" }]
},
{
    title: "nothing in my fridge !",
    tabimg: "../assets/images/foodimg1.png",
    buttons: [{ "button": "Beers & ciders" }, { "button": "Drinks" }, { "button": "alcohol" }, { "button": "tabacco" }, { "button": "Sweets" }, { "button": "pasta & rice" }, { "button": "sauces & condiments" }, { "button": "Daisy products" }, { "button": "Pet food" }, { "button": "Ice cream" }, { "button": "cleaning" }, { "button": "snacks" }, { "button": "breakfast" }]
}

]

export var hopperSlideList = [{
    "hopper_title": "Be a Deliveryman",
    "hopper_slide_img": "../assets/images/hopper.png",
    "hopper_dis": "Une communauté qui roule",
    "dbtn": "DEVENez Deliveryman"
}]

export var list_info = [{
    "name": "EASY JOB",
    "name_dis": "emploi du temps à la carte, revenus <br />simples et rapides"
},
{
    "name": "communauté",
    "name_dis": "Rejoins une communauté dynamique <br />et partage tes passions"
},
{
    "name": "privilège",
    "name_dis": "des offres et des évenements"

}]

export var slide_conList = [{
    "bag_img": "../assets/images/partner.png",
    "title": "Crave Tulum partner",
    "slide_dis": "delivered, won !",
    "dwnl_btn": "DEVENez partner"
}]

export var partnerList = [{ "data": "élargir son réseau de client" }, { "data": "augmenter la visibilité" }, { "data": "dynamiser les ventes" }, { "data": "satisfaire la demande" }, { "data": "integrer l’ère numérique" }]



export var ordList = [{
    "ord_title": "SUSHI",
    "ord_des": "Soupe miso - supplement onion, spicy, french fries, moustard & 1 drink",
    "ord_price": "CHF 25.-",
    "remove": "Remove",
    "modify": "Modify"

},
{
    "ord_title": "SUSHI",
    "ord_des": "Soupe miso - supplement onion<br /> Comment : lactose allergy",
    "ord_price": "CHF 25.-",
    "remove": "Remove",
    "modify": "Modify"
}
]

export var faqList = [{
    "que": "COMMENT INSTALLER L’APPLICATION ?",
    "ans": "Téléchargez l'application directement depuis votre smartphone sur le Play Store (Android) et l'App Store (iOS)."
},
{
    "que": "Combien coûte le service ?",
    "ans": "Vous ne payez que les produits choisis ainsi que les frais de livraison permettant de rémunérer votre Deliveryman."
},
{
    "que": "Comment se réalise le payment ?",
    "ans": "Le paiement s’effectue de manière sécurisée par carte de crédit (MasterCard ou Visa). "
}]

export var faqList2 = [{
    "que": "Que puis-je commander?",
    "ans": "Vous pouvez commander tout l'assortiment des restaurants, des supermarchés, des pharmacies et des fleuristes qui ont rejoint l'aventure Crave Tulum."
},
{
    "que": "où puis-je être livré ?",
    "ans": "Crave Tulum est actuellement disponible n’importe où à Genève ! Que vous soyez au bord du lac, à la maison ou au bureau, vous serez toujours livré !"
},
{
    "que": "Comment sont calculés les frais de livraison ?",
    "ans": "Les frais de livraison sont calculés sur la distance entre le point de retrait et le point de livraison."
}]

export var orderTable = [{
    "leftCon": "Subtotal",
    "o_price": "CHF 50.-"
},
{
    "leftCon": "Delivery fee",
    "o_price": "CHF 5.-"
},
{
    "leftCon": "Service fee",
    "o_price": "CHF 2.75.-"
}]



export var status = {

    order_accepted: "Order accepted",
    waiting_for_confirmed: "Waiting for confirmation ",
    waiting_for_store_confirmed: "Waiting for store confirmation ",
    order_prepared: "Order prepared",
    order_on_way: "Order on the way",
    order_doorstep: "Order on doorstep",

    created: "Created",
    waiting_for_accept: "Waiting for Accept",
    accepted: "Accepted",
    start_preparing_order: "Start Preparing order",
    order_ready: "Order Ready",
    waiting_for_delivery_man: "Waiting for Delivery Man",
    delivery_man_accepted: "Delivery Man Accepted",
    delivery_man_coming: "Delivery Man Coming",
    delivery_man_arrived: "Delivery Man Arrived",
    picked_order: "Delivery Man Picked Order ",
    started_for_delivery: "Started For Delivery",
    arrived_at_destination: "Delivery Man Arrived at Destination",
    delivered_ordered: "Delivered Order",
    assign_order_to_Delivery_man: "Order Assign To Delivery Man",
    rejected: "Rejected",
    cancelled: "Cancelled",
    store_cancelled: "Cancel By store",
    no_delivery_man_found: "No Delivery Man Found",
    delivery_man_rejected: "Delivery Man Rejected",
    delivery_man_cancelled: "Cancle By Delivery Man",
    user_cancelled: "Cancle By User",
    oredr_complete: "Order Complete",
    order_delivered: "Order Delivered",
    order_completed: 'Order Completed',
    completed: "Completed",
    not_completed: "Not Completed",
    cancelled_request: "Request Cancelled"


}
